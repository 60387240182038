import React from "react";
import { Controller } from "react-scrollmagic";
import "../assets/css/landing/terms.css";
import { LandingFooter } from "../components/landing/LandingFooter";
import { LandingHeader } from "../components/landing/LandingHeader";
import { LiquidityProvider } from "../components/landing/LiquidityProvider";

export const Exchange = () => {
  return (
    <Controller>
      <div className="Landing TermsConditions">
        <LandingHeader />
        <LiquidityProvider />
        <LandingFooter />
      </div>
    </Controller>
  );
};
