import React from "react";
import { Scene } from "react-scrollmagic";
import tick from "../../assets/img/landing/tick-purple.png";
import { EarningPotentialsList } from "../../utils/EarningPotentialsList";

export const EarningPotential = () => {
  return (
    <div className="ManagementSolution py-12 LandingCompanyOverView PlayerExp PaymentService relative overflow-hidden">
      <div className="common-width-landing">
        <div className="presentation flex flex-col pt-10 justify-center items-center text-center">
          <span>For Affiliates</span>
          <Scene triggerHook={0.8} reverse={false} classToggle={"scrollIn"}>
            
            <h1 className="mt-4 scrollOut mb-6 text-center mx-auto">
              Unlock Your <span>Earning</span> Potential
            </h1>
          </Scene>
          <Scene triggerHook={0.8} reverse={false} classToggle={"scrollIn"}>
            <p className="scrollOut w-[885px]">
              Join BetWin as an affiliate and unlock a world of opportunities
              to earn rewards from players, gaming operators, and investors. We
              value your contribution to our community, and we're here to help
              you maximize your earning potential.
            </p>
          </Scene>
        </div>

        <div className="mt-16 relative z-10 grid  grid-cols-1 gap-10">
          {EarningPotentialsList.map((EachCard, key) => (
            <div
              key={`p_${key}`}
              className={`card !bg-[#1A1A1B] !border-[#9435E2] p-12   z-10 rounded-xl`}
            >
              <div className="flex items-center gap-6">
                <div className="card-presentation">
                  <h1 className="!text-[29px]">{EachCard.heading}</h1>
                </div>
              </div>
              <ul className="mt-6 flex flex-col gap-4">
                {EachCard.list.map((EachList) => (
                  <li>
                    <h1 className={`!text-[16px] flex items-center gap-3`}>
                      <img src={tick} alt="" />
                      {EachList.heading}
                    </h1>
                    <p className="!text-[14px] ml-8 mt-2 !text-[#AEB2B1]">
                      {EachList.desc}
                    </p>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
