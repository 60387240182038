import { getRedirectResult, signInWithRedirect } from "firebase/auth";
import React, { useEffect, useState } from "react";
import { auth, provider } from "../../../config/firebaseConfig";

// components
import { Link, useNavigate } from "react-router-dom";
import button from "../../../assets/img/auth/google.png";
import { CheckboxStyleSignUp } from "../../../components/CheckboxStyleSignUp";

// stylesheet
import "../../../assets/css/sign-in.css";

// images

import axios from "axios";
import Cookies from "js-cookie";
import queryString from "query-string";
import { useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import eyeOpen from "../../../assets/img/auth/eye-open.svg";
import eye from "../../../assets/img/auth/eye.svg";
import ButtonLoader from "../../../components/ButtonLoader";
import { LandingFooter } from "../../../components/landing/LandingFooter";
import { LandingHeader } from "../../../components/landing/LandingHeader";
export const SignUp = () => {
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState(true);
  const location = useLocation();

  const [formData, setFormData] = useState({
    email: "",
    password: "",
    sponsor: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [userCode, setUserCode] = useState(null);
  const [copyButtonText, setCopyButtonText] = useState("Copy");
  const navigate = useNavigate();
  const sponsor = Cookies.get("referral") || "";

  // useEffect(() => {
  //   const queryParams = queryString.parse(location.search);
  //   const referralCookie = Cookies.get("referral");

  //   // If ref parameter exists in URL, use it over the cookie
  //   if (queryParams.ref) {
  //     Cookies.set("referral", queryParams.ref, { expires: 7 });
  //     setFormData((formData) => ({ ...formData, sponsor: queryParams.ref }));
  //   } else if (referralCookie) {
  //     // If no ref parameter but cookie exists, fill in the sponsor field
  //     setFormData((formData) => ({ ...formData, sponsor: referralCookie }));
  //   }
  // }, [location]);



  // useEffect(() => {
  //   const queryParams = queryString.parse(location.search);
  //   const referralCookie = Cookies.get("referral");

  //   // If ref parameter exists in URL, use it over the cookie
  //   if (queryParams.ref) {
  //     Cookies.set("referral", queryParams.ref, { expires: 7 });
  //     setFormData((formData) => ({ ...formData, sponsor: queryParams.ref }));
  //   } else if (referralCookie) {
  //     // If no ref parameter but cookie exists, fill in the sponsor field
  //     setFormData((formData) => ({ ...formData, sponsor: referralCookie }));
  //   }

  //   // If the referral code exists in the URL parameters, disable the input field
  //   if (queryParams.ref) {
  //     setDisableReferralField(true);
  //   }
  // }, [location]);

  useEffect(() => {
    const queryParams = queryString.parse(location.search);
    const referralCookie = Cookies.get("referral");

    // If ref parameter exists in URL, use it over the cookie
    if (queryParams.ref) {
      Cookies.set("referral", queryParams.ref, { expires: 7 });
      setFormData((formData) => ({ ...formData, sponsor: queryParams.ref }));
      setDisableReferralField(true); // Disable the referral field if referral code exists in URL
    } else if (referralCookie) {
      // If no ref parameter but cookie exists, fill in the sponsor field
      setFormData((formData) => ({ ...formData, sponsor: referralCookie }));
      setDisableReferralField(true); // Disable the referral field if referral code exists in cookie
    } else {
      // Enable the referral field if no referral code is present in URL or cookie
      setDisableReferralField(false);
    }
  }, [location]);


  // State to manage the disabled state of the referral code field
  const [disableReferralField, setDisableReferralField] = useState(false);



  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };


  const googleLoginWithFirebase = async () => {
    signInWithRedirect(auth, provider);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    const API_URL = process.env.REACT_APP_API_URL || "http://localhost:3000";

    try {
      const response = await axios.post(`${API_URL}/user/register`, formData);
      if (response.data.success) {
        setUserCode(response.data.userCode);
        setSubmitted(true);
      } else {
        // Handle the case where success is false
        toast.error(response.data.message); // Display error message using toast
      }
    } catch (error) {
      // Handle the case where the request failed to reach the backend or the backend returned a non-2xx status code
      console.error(
        "Signup error:",
        error.response ? error.response.data.message : error.message
      );
      toast.error(
        error.response
          ? error.response.data.message
          : "An error occurred during registration."
      );
    }
    setIsSubmitting(false);
  };

  const handleCopyClick = () => {
    navigator.clipboard.writeText(
      `${window.location.origin}/signup?ref=${userCode}`
    );
    setCopyButtonText("Copied"); // Change button text to "Copied"
    setTimeout(() => setCopyButtonText("Copy"), 2000); // Reset button text after 2 seconds
  };

  useEffect(() => {
    setLoading(true);

    getRedirectResult(auth)
      .then(async (result) => {
        if (result) {
          const idToken = await result.user.getIdToken();
          const response = await axios.post(
            `${process.env.REACT_APP_API_URL}/user/login/google`,
            { idToken, sponsor }
          );
          const dashboardURL =
            process.env.REACT_APP_DASHBOARD_URL || "http://localhost:3001";
          const token = response.data.token;
          window.location.href = `${dashboardURL}/dashboard?token=${token}`;
        }
      })
      .catch((error) => {
        toast.error("Google Sign-in failed");
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  if (submitted) {
    const referralLink = `${window.location.origin}/signup?ref=${userCode}`;

    return (
      <>
        <div className="Landing">
          <LandingHeader />
          <div className="text-white w-full md:w-2/3 mx-auto">
            {loading && (
              <div className="fixed left-0 top-0 flex items-center justify-center h-screen w-full bg-black z-[999]">
                <ButtonLoader />
              </div>
            )}
            <div className="grid grid-cols-1">
              <div className=" flex items-center justify-center flex-col p-8">
                <h2 className="text-2xl py-4 font-bold">Welcome to BetWin!</h2>
                <p className="m-4">
                  You've taken the first step into a world where gaming meets
                  growth, community, and innovation. This is just the beginning of
                  your journey with us.As part of our community, you're on the
                  forefront of transforming gaming into a platform for personal and
                  financial success. Your unique referral link is ready to share,
                  opening the door to a network of opportunities.
                </p>
                <p className="font-bold p-4">
                  Start building your network and be prepared for the launch of our
                  full suite of features:
                </p>
                <div className="flex my-10 justify-center items-center space-x-2">
                  <input
                    type="text"
                    value={referralLink}
                    readOnly
                    className="text-center bg-gray-700 border-0 rounded-md shadow-sm px-4 py-2 w-full"
                  />
                  <button
                    onClick={handleCopyClick}
                    className="bg-[#6fcf17] text-white px-4 py-2 rounded-md"
                  >
                    {copyButtonText}
                  </button>
                </div>
                <button
                  onClick={() => navigate("/signin")} // Use navigate to redirect to the sign-in page
                  className="mt-4 bg-[#6fcf17] text-white px-4 py-2 rounded-md"
                >
                  Log In Now
                </button>
                <p className="mt-4">
                  Staking, gaming, and a wealth of features are on the horizon. In
                  the meantime, use your referral link to invite others and grow
                  together with BetWin.
                </p>
              </div>
            </div>
          </div>
          <LandingFooter />
        </div>
      </>
    );
  }

  return (
    <div className="Landing">
      <LandingHeader />
      <div className="flex justify-center items-center  mt-[30px] mb-28">
        {loading && (
          <div className="fixed left-0 top-0 flex items-center justify-center h-screen w-full bg-black z-[999]">
            <ButtonLoader />
          </div>
        )}
        <ToastContainer />
        <div className="inner-work-sign-in grid grid-cols-2">
          <div className="left-side px-[26px] flex items-center justify-center flex-col">
            {/* <img src={signInMainImg} alt="sign in main presentation" /> */}
            <div className="presentation">
              <h1>Welcome to BetWin</h1>
              <p className="mt-4">
                Welcome to BetWin, where you've just made the perfect choice by
                signing up! We're thrilled to have you on board, and we're here to
                set you up for success.
              </p>
            </div>
          </div>
          <div className="right-side  px-8 py-10">
            <form onSubmit={handleSubmit} className="flex flex-col h-full">
              <h1 className="mb-8">Join BETWIN</h1>
              {/* <div className="tabs-wrapper gap-3 mb-5">
              <button className="rounded-lg active w-full">Email</button>
            </div> */}
              <div className="input-wrapper ">
                <label htmlFor="email">Email</label>
                <input
                  placeholder="Type your email here"
                  className="rounded-lg px-6 mt-3"
                  type="email"
                  name="email"
                  id="email"
                  onChange={handleChange}
                  value={formData.email}
                  required
                />
              </div>
              <div className="input-wrapper mt-4 mb-5">
                <label htmlFor="password">Password</label>
                <input
                  type={type ? "password" : type}
                  placeholder="Enter your password"
                  id="password"
                  name="password"
                  onChange={handleChange}
                  value={formData.password}
                  className="rounded-lg px-6 mt-3"
                  required
                />
                <img
                  src={type ? eye : eyeOpen}
                  className="eye-icon"
                  onClick={(e) => {
                    setType(!type);
                  }}
                  alt="eye icon"
                  style={{ width: "20px", height: "20px", cursor: "pointer" }}
                />
              </div>

              {/* <div className="input-wrapper">
              <label htmlFor="sponsor">
                Enter Referral / Promo Code (Optional)
              </label>
              <input
                type="text"
                name="sponsor"
                id="sponsor"
                onChange={handleChange}
                value={formData.sponsor}
                placeholder="Enter Referral / Promo Code (Optional)"
                className="rounded-lg px-6 mt-3"
              />
            </div> */}

              <div className="input-wrapper">
                <label htmlFor="sponsor">
                  Enter Referral / Promo Code (Optional)
                </label>
                <input
                  type="text"
                  name="sponsor"
                  id="sponsor"
                  onChange={handleChange}
                  value={formData.sponsor}
                  placeholder="Enter Referral / Promo Code (Optional)"
                  className="rounded-lg px-6 mt-3"
                  disabled={disableReferralField} // Disable the input field based on the disableReferralField state
                />
              </div>

              <div className="agreement-list mt-4">
                <div className="list-inner flex items-start">
                  <CheckboxStyleSignUp type="checkbox" id="user-agree" />
                  <p className="ml-3">
                    I agree to the User <a href="/">Agreement & Confirm</a> i am
                    at least 21 years old
                  </p>
                </div>

                <div className="list-inner mt-2 flex items-center">
                  <CheckboxStyleSignUp type="checkbox" id="marketing" />
                  <p className="ml-3" style={{ fontSize: 12 }}>
                    I agree to receive marketing promotions from BETWIN
                  </p>
                </div>
              </div>

              <button
                className="w-full rounded-lg mt-4 mb-4"
                type="submit"
                disabled={isSubmitting}
              >
                {isSubmitting ? <span>Loading...</span> : "Sign Up"}
              </button>
              <p className="dont-have-p">
                Already have an account! <Link to="/signin">Sign In</Link>
              </p>

              <div className="ending-point  flex-1 flex flex-col  justify-end">
                <div className="line-breaker flex items-center mt-8">
                  <span className="flex-1 mr-2"></span>
                  <p>Or</p>
                  <span className="flex-1 ml-2"></span>
                </div>

                <div className="mt-7 social-icons flex items-center justify-between">
                  <a
                    href="#"
                    className="w-full bg-blue-600 inline-flex justify-center py-2 rounded text-white"
                    onClick={(e) => {
                      e.preventDefault();
                      googleLoginWithFirebase();
                    }}
                  >
                    <img src={button} alt="social button" className="mr-2" />{" "}
                    Continue with google
                  </a>
                </div>
              </div>

              <div className="ending-point  flex-1 flex flex-col  justify-end">
                {/* <div className="line-breaker flex items-center mt-3">
                <span className="flex-1 mr-2"></span>
                <p>Or Login with</p>
                <span className="flex-1 ml-2"></span>
              </div> */}

                {/* <div className="mt-3 social-icons flex items-center justify-between">
                <a href="/">
                  <img src={button} alt="social button" />
                </a>
                <a href="/">
                  <img src={button1} alt="social button" />
                </a>
                <a href="/">
                  <img src={button2} alt="social button" />
                </a>
                <a href="/">
                  <img src={button3} alt="social button" />
                </a>
                <a href="/">
                  <img src={button4} alt="social button" />
                </a>
                <a href="/">
                  <img src={button5} alt="social button" />
                </a>
              </div> */}
              </div>
            </form>
          </div>
        </div>
      </div>
      <LandingFooter />
    </div>
  );
};
