import img1 from "../assets/img/landing/management1.png";
import img2 from "../assets/img/landing/management2.png";
import img3 from "../assets/img/landing/management3.png";
import img4 from "../assets/img/landing/management4.png";

export const ManagementCards = [
  {
    img: img1,
    heading: "Cross-Border and Cross-Chain Solutions",
    desc: "Offering a streamlined exchange service, bridging the gap between different currencies and blockchain networks for the gaming sector's financial needs.",
  },
  {
    img: img2,
    heading: "Crypto Cash and Gaming Chips Conversion",
    desc: "Facilitating the conversion of cryptocurrencies to traditional currencies or gaming chips, ensuring versatility for gamers across various platforms.",
  },
  {
    img: img3,
    heading: "Secured and Transparent Transactions",
    desc: "We prioritize security and transparency, ensuring all transactions are safe and transparent, meeting the highest industry standards.",
  },
  {
    img: img4,
    heading: "Global Partner Network",
    desc: "With partnerships in over 50 countries, our platform is fortified by traders and institutional partners, offering both centralized and decentralized exchange solutions.",
  },
];
