import React from "react";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import staker1 from "../../assets/img/landing/staker1.png";
import staker2 from "../../assets/img/landing/staker2.png";
import staker3 from "../../assets/img/landing/staker3.png";
import staker4 from "../../assets/img/landing/staker4.png";

export const ForStakers = ({ list }) => {
  return (
    <div className="stakers">
      <div className="cards-wrapper   grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-8 ">
        <div>
          <div className="card ">
            <img src={staker1} />
            <h1 className="mb-3 mt-6">Invest with Confidence</h1>
            <p>Join the ranks of successful investors in the thriving gaming industry with BetWin. Our expert team provides the guidance, due diligence, and market analysis you need to make informed investment decisions. With our support, step into the gaming world with confidence, knowing your investments are backed by industry expertise.</p>
          </div>
        </div>

        <div>
          <div className="card px-4 ">
            <img src={staker2} />
            <h1 className="mb-3 mt-6">Diverse Opportunities</h1>
            <p>BetWin offers a wide range of investment options within the dynamic gaming ecosystem. Whether you're interested in backing game development studios, eSports organizations, or other gaming-related ventures, we have opportunities that align with your investment goals. Explore the diversity of the gaming industry and uncover the most promising prospects for your portfolio.</p>
          </div>
        </div>

        <div>
          <div className="card px-4 ">
            <img src={staker3} />

            <h1 className="mb-3 mt-6">Sustainable Growth</h1>
            <p>
              Invest in the future of gaming, where the potential for
              sustainable growth knows no bounds. The gaming industry is
              continuously evolving, presenting exciting opportunities for
              investors. Our team of industry experts is here to guide you
              through the ever-changing landscape, ensuring your investments are
              poised for long-term success.
            </p>
          </div>
        </div>

        <div>
          <div className="card px-4 ">
            <img src={staker4} />
            <h1 className="mb-3 mt-6">Network & Collaborate</h1>
            <p>Connect with gaming industry leaders and like-minded investors within the BetWin community. Collaborate on exciting ventures, share insights, and stay ahead of market trends. Our network is your gateway to building valuable connections, fostering collaborations, and remaining at the forefront of the gaming industry.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForStakers;
