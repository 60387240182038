import React from "react";
import LiquidityCircle from "../../assets/img/landing/LiquidityCircle.svg";
import LiquidityMain from "../../assets/img/landing/LiquidityMain.png";
import blurlp from "../../assets/img/landing/blur-lp.png";
import pr1 from "../../assets/img/landing/pr1.svg";
import pr11 from "../../assets/img/landing/pr11.svg";
import pr12 from "../../assets/img/landing/pr12.svg";
import pr13 from "../../assets/img/landing/pr13.svg";
import pr14 from "../../assets/img/landing/pr14.svg";
import pr2 from "../../assets/img/landing/pr2.svg";
import pr3 from "../../assets/img/landing/pr3.svg";
import pr4 from "../../assets/img/landing/pr4.svg";
import pr5 from "../../assets/img/landing/pr5.svg";
import pr6 from "../../assets/img/landing/pr6.svg";
import pr7 from "../../assets/img/landing/pr7.svg";
import pr8 from "../../assets/img/landing/pr8.svg";
import pr9 from "../../assets/img/landing/pr9.svg";

import { Scene } from "react-scrollmagic";
export const LiquidityProvider = () => {
  return (
    <div
      className="ManagementSolution pt-[20px] sm:pt-[60px] md:pt-[60px] lg:pt-[100px] pb-[145px] md:pb-[60px] sm:pb-[60px] xs:pb-[50px]   LandingCompanyOverView PlayerExp PaymentService relative overflow-hidden"
      id="liq-provider"
    >
      <img
        src={blurlp}
        alt=""
        className="absolute blur-cards -left-8 -top-60 glowing-image-side "
      />
      <div className="common-width-landing grid grid-cols-2 items-center gap-[132px]">
        <div className="presentation">
          <Scene triggerHook={0.8} reverse={false} classToggle={"scrollIn"}>
            <h1 className="mt-4 scrollOut !text-[45px] mb-6  mx-auto">
              Our Liquidity Providers
            </h1>
          </Scene>
          <Scene triggerHook={0.8} reverse={false} classToggle={"scrollIn"}>
            <p className="scrollOut">
              BetWin is committed to providing gaming-related services and
              tools, but we do not offer investment opportunities or financial
              products. Our focus is on enhancing your gaming experience and
              operational capabilities within the gaming industry. Please be
              aware that BetWin is not an investment platform
            </p>
          </Scene>

          <Scene triggerHook={0.8} reverse={true} classToggle={"scrollIn"}>
            <div className="scrollOut overflow-hidden LiquidityProvider-grid-img mt-12 flex items-center gap-6">
              <div className="flex items-center gap-6 LiquidityProvider-work">
                <img src={pr1} alt="" />
                <img src={pr2} alt="" />
                <img src={pr3} alt="" />
                <img src={pr4} alt="" />
                <img src={pr5} alt="" />
                <img src={pr6} alt="" />
                <img src={pr7} alt="" />
                <img src={pr8} alt="" />
                <img src={pr9} alt="" />
                {/* <img src={pr10} alt="" /> */}
                <img src={pr11} alt="" />
                <img src={pr12} alt="" />
                <img src={pr13} alt="" />
                <img src={pr14} alt="" />
              </div>
            </div>
          </Scene>
        </div>

        <div className="w-full relative ">
          <img src={LiquidityCircle} alt="" className="w-full circle-liquid" />
          <img
            src={LiquidityMain}
            alt=""
            className="w-[50%] absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2"
          />
        </div>
      </div>
    </div>
  );
};
