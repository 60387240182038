import { Interweave } from "interweave";
import React from "react";
import { Controller } from "react-scrollmagic";
import "../assets/css/landing/terms.css";
import { LandingFooter } from "../components/landing/LandingFooter";
import { LandingHeader } from "../components/landing/LandingHeader";
import { disclaimer, policies } from "../utils/policies";

export const PrivacyPolicy = () => {
  return (
    <Controller>
      <div className="Landing TermsConditions">
        <LandingHeader />

        <div className="main-section py-[120px] w-[1055px] mx-auto">
          <h1 className="text-center mb-8">Privacy Policy</h1>
          <p className="w-[932px] text-center mx-auto">
            This Privacy Policy explains how we collect, use, and protect your
            personal information when you use our website and services. Please
            take a moment to read this Privacy Policy carefully.
          </p>

          <ol className="flex flex-col gap-[60px] mt-[100px]">
            {policies.map((eachterm, outerKey) => (
              <li key={outerKey}>
                <h1 className="heading-li mb-8">{eachterm.heading}</h1>
                <ol className="flex flex-col gap-6 ml-3">
                  {eachterm.list.map((EachList, innerKey) => (
                    <li key={innerKey}>
                      <Interweave content={EachList} />
                    </li>
                  ))}
                </ol>
              </li>
            ))}
            <h1 className="text-center legal-message-heading">
              Legal Disclaimer for BetWin
            </h1>

            {disclaimer.map((eachterm, outerKey) => (
              <li key={outerKey}>
                <h1 className="heading-li mb-8">{eachterm.heading}</h1>
                <ol className="flex flex-col gap-6 ml-3">
                  {eachterm.list.map((EachList, innerKey) => (
                    <li key={innerKey}>
                      <Interweave content={EachList} />
                    </li>
                  ))}
                </ol>
              </li>
            ))}

            <li className="outerlist">
              By using <span>BetWin's</span> website and services, you
              acknowledge that you have read, understood, and agreed to our
              <span>Privacy Policy</span> and <span>Legal Disclaimer</span>.
            </li>
          </ol>
        </div>

        <LandingFooter />
      </div>
    </Controller>
  );
};
