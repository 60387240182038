import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "../../../assets/css/sign-in.css";
import eye from "../../../assets/img/eye.svg";
import signInMainImg from "../../../assets/img/landing/sign-in-main-pic.png";
import ButtonLoader from "../../../components/ButtonLoader";
import { privateRequest } from "../../../config/axios.config";
export const CreateNewPassword = () => {
  const [type, setType] = useState(true);
  const [type2, setType2] = useState(true);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const navigate = useNavigate();

  const { state } = useLocation();
  const token = state?.token;
  const [isLoading, setIsLoading] = React.useState(false);


  useEffect(() => {
    console.log("State Values", token);
    if (!token) navigate('/forget-password');
  }, [token, navigate])



  const createNewPassword = (e) => {
    e.preventDefault()

    if (!password) return toast.error('Please enter your password')
    if (password !== confirmPassword) return toast.error('Password does not match')

    setIsLoading(true)

    privateRequest.post('user/password-reset/submit', {
      resetPasswordToken: token,
      password: password,
      confirmPassword: confirmPassword
    })
      .then(res => {
        setIsLoading(false)
        navigate("/password-change-done");
      })
      .catch(err => {
        setIsLoading(false)
        console.log(err, 'err')
        if (err.response?.data?.message) return toast.error(err.response.data.message)
        return toast.error('Something went wrong')
      })
  }




  return (
    <div className="SignIn LayerUp">
      <div className="inner-work-sign-in grid grid-cols-2">
        <div className="left-side px-[26px] flex items-center justify-center flex-col">
          <img src={signInMainImg} alt="sign in main presentation" />
          <div className="presentation">
            <h1>Welcome to BetWin</h1>
            <p className="mt-4">
              Welcome to BetWin, where you've just made the perfect choice by
              signing up! We're thrilled to have you on board, and we're here to
              set you up for success.
            </p>
          </div>
        </div>
        <div className="right-side  px-8 py-10">
          <form onSubmit={createNewPassword} className="flex flex-col h-full">
            <h1 className="mb-12">Create new password </h1>
            <div className="input-wrapper mb-8">
              <label htmlFor="password">New Password</label>
              <input
                type={type ? "password" : type}
                placeholder="Enter your password"
                id="password"
                className="rounded-lg px-6 mt-3"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />

              <img
                src={eye}
                className="eye-icon"
                onClick={(e) => {
                  setType(!type);
                }}
                alt="eye icon"
              />
            </div>

            <div className="input-wrapper mb-8">
              <label htmlFor="password">Confirm Password</label>
              <input
                type={type2 ? "password" : type2}
                placeholder="Enter your password"
                id="password"
                className="rounded-lg px-6 mt-3"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
              <img
                src={eye}
                className="eye-icon"
                onClick={(e) => {
                  setType2(!type2);
                }}
                alt="eye icon"
              />
            </div>

            <button className="w-full rounded-lg">
              {isLoading ? <ButtonLoader /> : "Create New Password"}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};
