import React from "react";
import { Scene } from "react-scrollmagic";
import tick from "../../assets/img/landing/tick-green.svg";

export const WhiteLabelSolution = () => {
  return (
    <div
      className="ManagementSolution pt-[120px] md:pt-[120px] sm:pt-[20px] xs:pt-[5px] LandingCompanyOverView PlayerExp PaymentService relative overflow-hidden"
      id="whitelabel-casino"
    >
      <div className="common-width-landing">
        <div className="presentation flex flex-col justify-center items-center text-center">
          <Scene triggerHook={0.8} reverse={false} classToggle={"scrollIn"}>
            <h1 className="mt-4 scrollOut mb-6 text-center mx-auto">
              White Label Solutions
            </h1>
          </Scene>
          <Scene triggerHook={0.8} reverse={false} classToggle={"scrollIn"}>
            <p className="scrollOut w-[885px]">
              BetWin offers comprehensive white label solutions powered by
              advanced AI software, enabling you to establish and operate your
              very own gaming brand. Our platform equips you with the necessary
              tools to create, manage, and customize your gaming enterprise
              efficiently.
            </p>
          </Scene>
        </div>

        <div className="mt-16 deploymentwrapper relative z-10 flex items-center gap-10">
          <div className="deployment min-w-[471px] w-[471px]">
            <h1 className="mb-6">Rapid Deployment</h1>
            <p className="mb-3">
              BetWin streamlines the process, enabling you to deploy your
              gaming brand swiftly. With our efficient solutions, launch your
              gaming enterprise within 7 to 10 days, quick and efficient entry
              into the gaming market.
            </p>
            <p>
              Explore the White Label Solutions by BetWin and embark on your
              journey to establish and manage your gaming brand effortlessly and
              rapidly.
            </p>
          </div>

          <div className="cards grid grid-cols-2 gap-6">
            <div className="card !bg-[#252525] !border-[#9435E2] rounded-xl p-6">
              <h1 className="flex items-center">
                <img src={tick} alt="" className="mr-3 w-[28px]" />
                Brand Creation and Operation
              </h1>
              <p className="ml-[40px] mt-3">
                Tailor and establish your unique gaming brand with the support
                of BetWin's cutting-edge AI solutions. Build, launch, and
                operate your gaming platform effortlessly.
              </p>
            </div>
            <div className="card !bg-[#252525] !border-[#9435E2] rounded-xl p-6">
              <h1 className="flex items-center">
                <img src={tick} alt="" className="mr-3 w-[28px]" />
                Complete Management Tools
              </h1>
              <p className="ml-[40px] mt-3">
                Access a suite of tools providing game management,
                administrative panels, dashboards, and essential elements for
                streamlined gaming operation.
              </p>
            </div>
            <div className="card !bg-[#252525] !border-[#9435E2] rounded-xl p-6">
              <h1 className="flex items-center">
                <img src={tick} alt="" className="mr-3 w-[28px]" />
                Domain Setup and Hosting
              </h1>
              <p className="ml-[40px] mt-3">
                Benefit from seamless domain setup and reliable hosting services
                to ensure a smooth online presence for your gaming brand.
              </p>
            </div>
            <div className="card !bg-[#252525] !border-[#9435E2] rounded-xl p-6">
              <h1 className="flex items-center">
                <img src={tick} alt="" className="mr-3 w-[28px]" />
                High-Level Security
              </h1>
              <p className="ml-[40px] mt-3">
                Prioritize security with BetWin's high-level protection
                protocols, safeguarding your gaming platform and user data.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
