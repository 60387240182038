import img1 from "../assets/img/landing/keyfeature1.svg";
import img2 from "../assets/img/landing/keyfeature2.svg";
import img3 from "../assets/img/landing/keyfeature3.svg";
import img4 from "../assets/img/landing/keyfeature4.svg";

export const KeyFeaturesList = [
  {
    img: img1,
    heading: "Players",
    list: [
      {
        heading: "Diverse Game Library",
        desc: "Access a vast selection of games across genres and platforms.",
      },
      {
        heading: "High-Quality Gameplay",
        desc: "Expreience cutting-edge technology and immersive gameplay.",
      },
      {
        heading: "Community Engagement ",
        desc: "Connect with fellow gamers, form alliances and share experiences.",
      },
      {
        heading: "Rewards and Incentives",
        desc: "Enjoy bonuses, competitions, and exclusive in the game and rewards.",
      },
    ],
  },
  {
    img: img2,
    heading: "Affiliates",
    list: [
      {
        heading: "Affiliate Programs",
        desc: "Earn rewards by connecting players, gaming operators, and stakers with Betwin.",
      },
      {
        heading: "Transparent Analytics",
        desc: "Access real-time data and analytics for performance tracking.",
      },
      {
        heading: "Marketing Support",
        desc: "Receive marketing materials and guidance for effective promotion.",
      },
      {
        heading: "Tailored Campaigns",
        desc: "Create customized campaigns to target specific audiences.",
      },
    ],
  },
  {
    img: img3,
    heading: "Gaming Operators",
    list: [
      {
        heading: "Game Development Collaboration",
        desc: "Partner with top developers for the exclusive with our titles.",
      },
      {
        heading: "Marketing Strategies",
        desc: "Access tailored marketing strategies to reach your target audience.",
      },
      {
        heading: "Data-Driven Insights",
        desc: "Utilize data analytics for informed decision-making with our BetWin.",
      },
      {
        heading: "Monetization Solutions",
        desc: "Explore various revenue streams while ensuring player satisfaction.",
      },
    ],
  },

  {
    img: img4,
    heading: "Stakers",
    list: [
      {
        heading: "Staking Opportunities",
        desc: "Invest in the gaming industry with diverse stake options.",
      },
      {
        heading: "ROI Tracking",
        desc: "Monitor returns on your investments through transparent reporting.",
      },
      {
        heading: "Community Engagement",
        desc: "Connect with other stakers and gain insights into market trends.",
      },
      {
        heading: "Access to Exclusive Deals",
        desc: "Get early access to promising gaming project with the BetWin.",
      },
    ],
  },
];
