import React, { useState } from "react";
import { Controller, Scene } from "react-scrollmagic";
import { EarningPotential } from "../components/landing/EarningPotential";
import { ForPlayers } from "../components/landing/ForPlayers";
import { ForStakers } from "../components/landing/ForStakers";
import { GameOperator } from "../components/landing/GameOperator";
import { KeyFeature } from "../components/landing/KeyFeature";
import { KeySolution } from "../components/landing/KeySolution";
import { LandingCompanyOverView } from "../components/landing/LandingCompanyOverView";
import { LandingFooter } from "../components/landing/LandingFooter";
import { LandingHeader } from "../components/landing/LandingHeader";
import { LandingMain } from "../components/landing/LandingMain";
import { LiquidityProvider } from "../components/landing/LiquidityProvider";
import { ManagementSolution } from "../components/landing/ManagementSolution";
import { Partners } from "../components/landing/Partners";
import { Provder } from "../components/landing/Provder";
import { WhiteLabelSolution } from "../components/landing/WhiteLabelSolution";
import { firstList } from "../utils/SportsBook";

export const Landing = () => {
  // state work
  const [prizeState, setprizeState] = useState(true);
  return (
    <Controller>
      {/* {prizeState && <Prize setprizeState={setprizeState} />} */}
      <div className="Landing">
        <LandingHeader setprizeState={setprizeState} />
        <LandingMain />
        <LandingCompanyOverView />

        <div className="long-bar-wrapper overflow-hidden  pb-[20px] xs:pb-[20px] sm:pb-[20px] md:pb-[40px] lg:pb-[40px]  ">
          <Provder />
          <KeySolution />
          <ManagementSolution />
          <KeyFeature />
          <ForPlayers />
          <WhiteLabelSolution />
        </div>

        <div className="long-bar-wrapper !bg-[transparent]  pb-[80px]">
          <LiquidityProvider />
          <EarningPotential />
        </div>

        <div className="long-bar-wrapper    pb-[50px] md:pb-[40px] sm:pb-[30px] xs:pb-[10px] ">
          <div
            className="cansioSports ServicesWrapper relative overflow-hidden"
            id="manage-services"
          >
            <GameOperator />

            <div
              className="cansioSports pt-[80px] md:pt-[60px] sm:pt-[20px] xs:pt-[5px] pb-[40] md:pb-[30px] sm:pb-[20px] xs:pb-[10px]  sportsbook relative overflow-hidden"
              id="for-stakers"
              style={{ backgroundColor: '#1A1A1B' }}
            >
              <div className="presentation common-width-landing mb-16 flex flex-col justify-center items-center text-center">
                <Scene
                  triggerHook={0.8}
                  reverse={false}
                  classToggle={"scrollIn"}
                >
                  <h1 className="mt-4 mb-6 text-center mx-auto scrollOut">
                    For Stakers
                  </h1>
                </Scene>
                <Scene
                  triggerHook={0.8}
                  reverse={false}
                  classToggle={"scrollIn"}
                >
                  <p className="scrollOut w-[885px]">
                    BetWin offers comprehensive white label solutions powered
                    by advanced AI software, enabling you to establish and
                    operate your very own gaming brand. Our platform equips you
                    with the necessary tools to create, manage, and customize
                    your gaming enterprise efficiently.
                  </p>
                </Scene>
              </div>

              <ForStakers list={firstList} />
            </div>
          </div>
        </div>

        <Partners />

        <LandingFooter />
      </div>
    </Controller>
  );
};
