export const policies = [
  {
    heading: "1. Information We Collect",
    list: [
      "1.1 <span class='bold'>Personal Information:</span> We may collect personal information that you provide to us, including but not limited to your name, email address, phone number, and other contact information.",
      "1.2 <span class='bold'>Transaction Information:</span> We may collect information about your transactions, including cryptocurrency transactions, on our platform.",
      "1.3 <span class='bold'>Usage Information:</span> We may collect information about your use of our website and services, including your IP address, browser type, and operating system.",
    ],
  },

  {
    heading: "2. How We Use Your Information",
    list: [
      "2.1 <span class='bold'>Providing Services:</span> We use your information to provide, maintain, and improve our services, including processing transactions and providing customer support.",
      "2.2 <span class='bold'>Communication:</span> We may use your contact information to communicate with you about our services, updates, and promotions.",
      "2.3 <span class='bold'>Security:</span> We use your information to monitor and enhance the security of our platform, including fraud prevention",
    ],
  },

  {
    heading: "3. Disclosure of Your Information",
    list: [
      "3.1 <span class='bold'>Legal Compliance:</span> We may disclose your information as required by law or to protect our rights, privacy, safety, or property, or that of our affiliates, users, or the public.",
      "3.2 <span class='bold'>Service Providers:</span> We may share your information with service providers who assist us in providing our services.",
    ],
  },
  {
    heading: "4. Security",
    list: [
      "4.1 We employ security measures to protect your information; however, no data transmission over the internet or storage system can be guaranteed as completely secure.",
    ],
  },

  {
    heading: "5. Cookies and Tracking Technologies",
    list: [
      "5.1 We may use cookies and similar tracking technologies to collect information about your interactions with our website.",
    ],
  },

  {
    heading: "6. Your Choices",
    list: [
      "6.1 You can access, correct, or delete your personal information by contacting us. You may also opt out of promotional emails.",
    ],
  },

  {
    heading: "7. Jurisdiction",
    list: ["7.1 This Privacy Policy is governed by the laws of Curacao."],
  },

  {
    heading: "8. Changes to this Privacy Policy",
    list: [
      "8.1 We may update this Privacy Policy to reflect changes to our information practices. We will notify you of any significant changes.",
    ],
  },

  {
    heading: "9. Contact Us",
    list: [
      "9.1 If you have questions or concerns about this Privacy Policy, please contact us at <a href='support@betwin789.net'>support@betwin789.net</a>",
    ],
  },
];
export const disclaimer = [
  {
    heading: "1. No Financial Advice",
    list: [
      "1.1 BetWin does not provide financial or investment advice. Information on our website is for informational purposes only.",
    ],
  },
  {
    heading: "2. Risk Warning",
    list: [
      "2.1 Participation in gaming, cryptocurrencies, and financial transactions carries inherent risks. Users should exercise caution and conduct their own research before participating.",
    ],
  },
  {
    heading: "3. Jurisdiction",
    list: [
      "3.1 BetWin operates under the jurisdiction of Curacao. Users are responsible for compliance with the laws and regulations of their jurisdiction.",
    ],
  },
];
