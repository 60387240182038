import React from "react";

import { Scene } from "react-scrollmagic";
import { ManagementCards } from "../../utils/ManagementCards";
export const ManagementSolution = () => {
  return (
    <div className="ManagementSolution pt-[160px] md:pt-[20px] sm:pt-[20px] xs:pt-[5px] relative overflow-hidden" style={{ backgroundColor: '#111010' }}>
      <div className="common-width-landing">
        <div className="mt-[60px] md:mt-[20px] sm:mt-[20px] xs:mt-[2px] grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
          {ManagementCards.map((EachCard, key) => (
            <div
              className="card px-4 !bg-[#252525] !border-[#3C403F] rounded-lg py-6 flex flex-col items-center"
              key={`m_${key}`}
            >
              <Scene triggerHook={0.8} reverse={false} classToggle={"scrollIn"}>
                <img src={EachCard.img} alt="" className="scrollOut" />
              </Scene>
              <h1 className="mt-6 mb-3 text-center">{EachCard.heading}</h1>
              <p className="text-center">{EachCard.desc}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
