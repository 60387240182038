import React from "react";
import arrowBtn from "../../assets/img/landing/arrow-btn.svg";
import greenTick from "../../assets/img/landing/tick-green.svg";
import keyfeaturespider from "../../assets/img/landing/keyfeaturespider.png";

import { Link } from "react-router-dom";
import { Scene } from "react-scrollmagic";
import { KeyFeaturesList } from "../../utils/KeyFeaturesList";
export const KeyFeature = () => {
  return (
    <div
      className="CasinoPlatform  pt-[120px] md:pt-[120px] sm:pt-[80px] xs:pt-[20px] common-width-landing relative z-10"
      id="keyfeatures"
    >
      <span className="flex items-center scrollOut justify-center ">
        Features
      </span>
      <h1 className="mt-4 mb-6 ">
        Our Key <span>Features</span>
      </h1>
      <div className="CasinoPlatformFirstWrapper   flex items-center justify-between">
        <p>
          BetWin offers a comprehensive service platform designed to cater to
          the diverse needs of players, affiliates, gaming operators and
          stakers.
        </p>
        <Link to="/" className="flex items-center justify-center rounded-lg">
          <img src={arrowBtn} alt="" className="mr-2" />
          Get Started
        </Link>
      </div>
      <div className="grid grid-cols-2 gap-11">
        <img
          src={keyfeaturespider}
          alt=""
          className="absolute w-[45%] spider-img top-[470px] left-1/2 -translate-x-1/2 "
        />
        {KeyFeaturesList.map((EachCard, key) => (
          <div
            key={`p_${key}`}
            className={`card !bg-[#252525] !border-[#9435E2] p-6   w-[435px] lg:w-[435px] md:w-full sm:w-full z-10 rounded-xl ${
              (key + 1) % 2 === 0 && "!justify-self-end"
            } `}
          >
            <div className="flex items-center gap-6">
              <Scene triggerHook={0.8} reverse={false} classToggle={"scrollIn"}>
                <img
                  src={EachCard.img}
                  alt=""
                  className="scrollOut !min-w-12 !min-h-12"
                />
              </Scene>

              <div className="card-presentation">
                <h1 className="!text-[29px]">{EachCard.heading}</h1>
              </div>
            </div>
            <ul className="mt-6 flex flex-col gap-4">
              {EachCard.list.map((EachList) => (
                <li>
                  <h1 className={`!text-[16px] flex items-center gap-3`}>
                    <img src={greenTick} alt="" />
                    {EachList.heading}
                  </h1>
                  <p className="!text-[14px] ml-8 mt-2 !text-[#AEB2B1]">
                    {EachList.desc}
                  </p>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </div>
  );
};
