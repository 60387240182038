import img1 from "../assets/img/landing/key1.svg";
import img2 from "../assets/img/landing/key2.svg";
import img3 from "../assets/img/landing/key3.svg";
import img4 from "../assets/img/landing/key4.svg";
import img5 from "../assets/img/landing/key5.svg";
import img6 from "../assets/img/landing/key6.svg";
import img7 from "../assets/img/landing/key7.svg";
import img8 from "../assets/img/landing/key8.svg";

export const KeySolutions = [
  {
    img: img1,
    heading: "Cross-Border Liquidity",
    desc: "Seamlessly transfer funds across borders, simplifying international transactions and removing geographical constraints.",
  },
  {
    img: img2,
    heading: "Cross-Chain Integration",
    desc: "Bridge the gap between different blockchain networks enabling users to  move assets effortlessly between chains.  ",
  },
  {
    img: img3,
    heading: "Cash2 Crypto",
    desc: "Convert traditional cash into cryptocurrencies or gaming chips seamlessly providing users with unparalleled versatility.",
  },

  {
    img: img4,
    heading: "Low Transaction ",
    desc: "Enjoy cost effective transactions with minimal fees making Game Box change a cost efficient choice for users.",
  },
  {
    img: img5,
    heading: "Extensive Crypto Selection",
    desc: "Provide access to over 100 cryptocurrencies with high marker capitalization and liquidity ensuring users can trade their preferred digital assets",
  },
  {
    img: img6,
    heading: "Currency Flexibility",
    desc: "Support more than 20 fiat currencies allowing users from diverse regions to participate effortlessly",
  },

  {
    img: img7,
    heading: "Fintech and DeFi Integration",
    desc: "Harness the power of fintech and decentralized finance (DeFi) structure to optimize security transparency and efficiency in financial transactions.",
  },
  {
    img: img8,
    heading: "Gaming Chip Integration",
    desc: "Collaborate with gaming industry leaders to offer access to gaming chips from our global partners both in the online and land based gaming sectors.",
  },
];
