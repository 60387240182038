import React, { useState } from "react";
import globe from "../assets/img/about/globe.svg";
import { LandingFooter } from "../components/landing/LandingFooter";
import { LandingHeader } from "../components/landing/LandingHeader";
import { AboutCards } from "../utils/AboutCards";
export const About = () => {
  // state work
  const [prizeState, setprizeState] = useState(true);
  return (
    <div>
      {/* {prizeState && <Prize setprizeState={setprizeState} />} */}
      <div className="Landing">
        <LandingHeader setprizeState={setprizeState} />
        <div className="flex flex-col mt-[140px] mb-[80px] items-center justify-center common-width-landing sm:mt-[50px] sm:mb-[40px]">
          <span className="text-[#9DF74A] text-[14px] font-arabicreg">
            About Us
          </span>
          <h1 className="text-[58px] md:text-[35px] sm:!text-[30px] mt-4 mb-6 text-center text-white font-arabiclight">
            Introducing the Visionaries <br /> Behind{" "}
            <span className="text-[#9DF74A] font-arabicbold">BetWin789</span>
          </h1>
          <p className="text-center  text-[#AEB2B1] lg:text-[20px] font-arabicmed md:w-full md:text-[16px] sm:!text-[14px]">
            Let us introduce you to the brilliant minds who have played a
            pivotal role in shaping BetWin789 into the platform it is today.
          </p>
        </div>
        <div className="cards-wrapper lg:grid-cols-3 md:!grid-cols-2 grid grid-cols-1 gap-6 px-2">
          {AboutCards.map((EachCard, key) => (
            <div
              className="card px-4 !bg-[#252525] !border-[#3C403F] rounded-lg py-6 flex flex-col items-center  "
              key={`m_${key}`}
            >
              <img src={EachCard.img} alt="" />
              <h1 className="mt-6 mb-3 text-center md:text-[18px] text-[22px] font-arabicmed text-white">
                {EachCard.heading}
              </h1>
              <p className="text-center md:text-[14px] text-[#AEB2B1] font-arabicreg text-[16px]">
                {EachCard.desc}
              </p>
            </div>
          ))}
        </div>

        <div className="text-center mt-10">
          <h2 className="text-[#9DF74A] text-[14px] text-center font-arabicreg">
            Our team
          </h2>
        </div>
        <div className="grid lg:grid-cols-2 grid-cols-1 gap-10 items-center common-width-landing pb-48 sm:!py-[50px] sm:!pb-0">
          <div className="flex flex-col ">
          <h1 className="text-[58px] text-left sm:!text-[30px] md:text-[35px] mt-4 mb-6 text-white font-arabiclight">
            Powered by a 100-
            Person Strong{" "}
            <span className="text-[#9DF74A] font-arabicbold">Team</span>
          </h1>
            <p className="text-left md:text-[16px]  text-[#AEB2B1] text-[20px] font-arabicmed  sm:!text-[14px] md:text-center">
              Our team is a powerhouse of over 100 dedicated professionals
              hailing from Asia, Europe, and the Americas. This diversity
              enriches our pool of skills and perspectives, propelling
              BetWin789.net to the forefront of the gaming industry, where
              innovation and excellence are our guiding principles.
            </p>
          </div>
          <img src={globe} alt="" width={500} />
        </div>
        <LandingFooter />
      </div>
    </div>
  );
};
