import React, { useEffect, useState } from "react";
import "../../assets/css/landing/index.css";
// import logoheader from "../../assets/img/landing/logoheader.webp";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import logoheader from "../../assets/img/landing/betwin789.webp";

export const LandingHeader = ({ setprizeState }) => {
  const [Nav, setNav] = useState(false);

  const [scrolled, setScrolled] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 0;
      setScrolled(isScrolled);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    window.gtranslateSettings = {
      default_language: "en",
      native_language_names: true,
      languages: [
        "en",
        "es",
        "ur",
        "zh-CN",
        "pt",
        "ja",
        "th",
        "vi",
        "hi",
        "ru",
        "de",
        "fr",
      ],
      wrapper_selector: ".gtranslate_wrapper",
    };
    const script = document.createElement("script");
    script.src = "https://cdn.gtranslate.net/widgets/latest/dwf.js";
    script.async = true;
    script.defer = true;
    document.querySelector(".LandingHeader").appendChild(script);
  }, []);

  return (
    <section
      className={`LandingHeader flex justify-center ${scrolled ? "scrolled" : ""
        }`}
    >
      <div className="flex items-center justify-between common-width-landing">
        <Link to="/">
          <img src={logoheader} alt="" className="logo w-56" />
        </Link>
        <nav className={`flex items-center ${Nav && "active"}`}>
          <li className="mr-4">
            <Link to="/" className="active">
              Home
            </Link>
          </li>
          <li className="mr-4">
            <Link to="/about">About us</Link>
          </li>
          <li className="mr-4">
            <HashLink to="/#vip_service">Services</HashLink>
          </li>
          <li className="mr-4">
            <Link to="/business-partners">Partners</Link>
          </li>
          {/* <li className="mr-4">
            <Link to="/Roadmap">Roadmap</Link>
          </li> */}
          {/* <li className="mr-4">
            <a
              href="#game-op"
              onClick={(e) => {
                e.preventDefault();
                setprizeState(true);
              }}
            >
              News and Events
            </a>
          </li> */}
          <li className="mr-4">
            <HashLink to="https://medium.com/@betwin789">News and Events</HashLink>
          </li>
          <li className="mr-4">
            <Link to="/contact">Contact us</Link>
          </li>

          <div className="button-wrapper button-wrapper-1 hidden  items-center">
            <div className="signin">
              <Link
                to="/signin"
                className="px-3 flex items-center justify-center rounded-lg"
              >
                Log in
              </Link>
            </div>
            <div className="signup">
              <Link
                to="/signup"
                className="px-3 flex items-center justify-center rounded-lg"
              >
                Sign up
              </Link>
            </div>
          </div>

          <div className="gtranslate_wrapper  gtranslate_wrapper_modile ml-32 hidden"></div>
        </nav>

        <div className="button-wrapper button-wrapper-2  flex items-center">
          <div className="gtranslate_wrapper mr-2"></div>
          <div className="signin">
            <Link
              to="/signin"
              className="px-3 flex items-center justify-center rounded-lg"
            >
              Log in
            </Link>
          </div>
          <div className="signup">
            <Link
              to="/signup"
              className="px-3 flex items-center justify-center rounded-lg"
            >
              Sign up
            </Link>
          </div>
        </div>

        <button
          className="burger-btn hidden ml-2"
          onClick={(e) => {
            setNav(!Nav);
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="#AEB2B1"
            className="w-6 h-6"
          >
            <path
              fillRule="evenodd"
              d="M3 6.75A.75.75 0 013.75 6h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 6.75zM3 12a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 12zm0 5.25a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75a.75.75 0 01-.75-.75z"
              clipRule="evenodd"
            />
          </svg>
        </button>
      </div>
    </section>
  );
};
