import { getRedirectResult, signInWithRedirect } from "firebase/auth";
import Cookies from "js-cookie";
import React, { useEffect, useRef, useState } from "react";
import { auth, provider } from "../../../config/firebaseConfig";

// images
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import eyeOpen from "../../../assets/img/auth/eye-open.svg";
import eye from "../../../assets/img/auth/eye.svg";
import button from "../../../assets/img/auth/google.png";
import ButtonLoader from "../../../components/ButtonLoader";

// stylesheet
import "../../../assets/css/landing/signin.css";
import "../../../assets/css/sign-in.css";

// components
import axios from "axios";
import { Link } from "react-router-dom";
import { CheckboxStyle } from "../../../components/CheckboxStyle";
import { LandingFooter } from "../../../components/landing/LandingFooter";
import { LandingHeader } from "../../../components/landing/LandingHeader";

export const Signin = () => {
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState(true);
  const [formData, setFormData] = useState({ email: "", password: "" });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isOtp, setIsOtp] = useState(false);
  const [code, setCode] = React.useState(["", "", "", "", "", ""]);
  const inputRefs = useRef([]);

  const sponsor = Cookies.get("referral") || "";

  const focusOnNextInput = (index) => {
    const nextInput = document.getElementById(`text-${index + 1}`);
    if (nextInput) {
      nextInput.focus();
    }
  };

  const codeChangeHandler = (e, index) => {
    const value = e.target.value;
    const newCode = [...code];
    newCode[index] = value.slice(0, 1); // Ensure only one character

    // Move to the next input field if the current input has a value
    if (value && index < 5) {
      inputRefs.current[index + 1].focus();
    } else if (!value && index > 0) {
      inputRefs.current[index - 1].focus();
    }

    setCode(newCode);
  };

  const handlePaste = (e) => {
    const pasteData = e.clipboardData.getData("text").slice(0, 6).split("");
    const newCode = [...code];
    pasteData.forEach((char, index) => {
      if (index < 6) {
        newCode[index] = char;
      }
    });
    setCode(newCode);
    if (pasteData.length < 6) {
      inputRefs.current[pasteData.length].focus();
    } else {
      inputRefs.current[5].focus();
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && !code[index] && index > 0) {
      const newCode = [...code];
      newCode[index - 1] = "";
      setCode(newCode);
      inputRefs.current[index - 1].focus();
    }
  };

  const googleLoginWithFirebase = async () => {
    signInWithRedirect(auth, provider);
  };

  const handleLogin = async (e) => {
    e.preventDefault();

    if (isOtp && code?.join("").length < 6)
      return toast.error("Please enter the code");

    setIsSubmitting(true);

    try {
      const API_URL = process.env.REACT_APP_API_URL || "http://localhost:3000";
      const payload = {
        email: formData.email,
        password: formData.password,
      };
      if (isOtp) payload.otp = code?.join("");

      const response = await axios.post(`${API_URL}/user/login`, payload);

      if (response.data.success) {
        // Instead of setting token in localStorage, pass it in the URL
        const dashboardURL =
          process.env.REACT_APP_DASHBOARD_URL || "http://localhost:3000";
        const token = response.data.token;
        window.location.href = `${dashboardURL}/dashboard?token=${token}`;
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      if (error.response.data?.message?.toLowerCase().includes("otp")) {
        setIsOtp(true);
      }
      toast.error(
        error.response?.data.message || "Login failed. Please try again."
      );
    } finally {
      setIsSubmitting(false);
    }
  };
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.id]: e.target.value });
  };

  useEffect(() => {
    inputRefs?.current[0]?.focus();
    console.log(inputRefs);
  }, []);

  useEffect(() => {
    setLoading(true);

    getRedirectResult(auth)
      .then(async (result) => {
        if (result) {
          const idToken = await result.user.getIdToken();
          const response = await axios.post(
            `${process.env.REACT_APP_API_URL}/user/login/google`,
            { idToken, sponsor }
          );
          const dashboardURL =
            process.env.REACT_APP_DASHBOARD_URL || "http://localhost:3001";
          const token = response.data.token;
          window.location.href = `${dashboardURL}/dashboard?token=${token}`;
        }
      })
      .catch((error) => {
        toast.error("Google Sign-in failed");
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <div className="Landing">
      <LandingHeader />
      <div className="flex justify-center items-center mt-[30px] mb-28">
        {loading && (
          <div className="fixed left-0 top-0 flex items-center justify-center h-screen w-full bg-black z-[999]">
            <ButtonLoader />
          </div>
        )}
        <div className="inner-work-sign-in grid grid-cols-2">
          <div className="left-side px-[26px] flex items-center justify-center flex-col">
            {/* <img src={signInMainImg} alt="sign in main presentation" /> */}
            <div className="presentation">
              <h1>Welcome to BetWin</h1>
              <p className="mt-4">
                Welcome to BetWin, where you've just made the perfect choice by
                signing up! We're thrilled to have you on board, and we're here
                to set you up for success.
              </p>
            </div>
          </div>
          <div className="right-side  px-8 py-10">
            <form onSubmit={handleLogin} className="flex flex-col h-full">
              {" "}
              {isOtp && formData.password !== "" ? (
                <>
                  <h1 className="mt-12">Enter Your One-Time Password (OTP)</h1>
                  <p className="text-white py-5">
                    Please type the 6-digit OTP from the Google Authenticator
                    app to proceed.
                  </p>
                  <div className="mb-10 input-wrapper grid grid-cols-6 gap-3">
                    {code.map((digit, index) => (
                      <input
                        key={index}
                        type="text"
                        value={digit}
                        onChange={(e) => codeChangeHandler(e, index)}
                        onKeyDown={(e) => handleKeyDown(e, index)}
                        onPaste={handlePaste}
                        maxLength={1}
                        ref={(el) => (inputRefs.current[index] = el)}
                        className="rounded-lg text-center !text-[20px] !text-white !w-[50px] !h-[50px]"
                      />
                    ))}
                  </div>
                </>
              ) : (
                <>
                  <h1 className="mb-12">Sign in</h1>
                  <div className="input-wrapper">
                    <label htmlFor="email">Email</label>
                    <input
                      type="text"
                      placeholder="Type your email here"
                      id="email"
                      className="rounded-lg px-6 mt-3"
                      value={formData.email}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="input-wrapper mt-5 mb-9">
                    <label htmlFor="password">Login Password</label>
                    <input
                      type={type ? "password" : type}
                      placeholder="Enter your passwoard"
                      id="password"
                      className="rounded-lg px-6 mt-3"
                      value={formData.password}
                      onChange={handleChange}
                    />
                    <img
                      src={type ? eye : eyeOpen}
                      className="eye-icon"
                      onClick={(e) => {
                        setType(!type);
                      }}
                      alt="eye icon"
                      style={{
                        width: "20px",
                        height: "20px",
                        cursor: "pointer",
                      }}
                    />
                  </div>
                </>
              )}
              <div className="flex items-center justify-between remember-me-portion">
                <div className="checkbox-wrapper flex items-center">
                  <CheckboxStyle id="remember-me" type="checkbox" />
                  <label htmlFor="" className="ml-1">
                    Remember me
                  </label>
                </div>
                <Link to="/forget-password">Forgot Password?</Link>
              </div>
              <button
                className="w-full rounded-lg mt-4 mb-9"
                disabled={isSubmitting}
              >
                {isSubmitting ? "Signing In..." : "Sign In"}
              </button>
              <p className="dont-have-p">
                Don’t have an account! <Link to="/signup">Sign up</Link>
              </p>
              <div className="ending-point  flex-1 flex flex-col  justify-end">
                <div className="line-breaker flex items-center mt-2">
                  <span className="flex-1 mr-2"></span>
                  <p>Or</p>
                  <span className="flex-1 ml-2"></span>
                </div>

                <div className="mt-7 social-icons flex items-center justify-between">
                  <a
                    href="#"
                    className="w-full bg-blue-600 inline-flex justify-center py-2 rounded text-white"
                    onClick={(e) => {
                      e.preventDefault();
                      googleLoginWithFirebase();
                    }}
                  >
                    <img src={button} alt="social button" className="mr-2" />{" "}
                    Continue with google
                  </a>
                  {/* <a href="/">
                  <img src={button1} alt="social button" />
                </a>
                <a href="/">
                  <img src={button2} alt="social button" />
                </a>
                <a href="/">
                  <img src={button3} alt="social button" />
                </a>
                <a href="/">
                  <img src={button4} alt="social button" />
                </a>
                <a href="/">
                  <img src={button5} alt="social button" />
                </a> */}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <LandingFooter />
    </div>
  );
};
