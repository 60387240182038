import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyAKa45vY-5xnOsrnE7R9waVtL3HcDEaP18",
  authDomain: "betwin789auth.firebaseapp.com",
  projectId: "betwin789auth",
  storageBucket: "betwin789auth.appspot.com",
  messagingSenderId: "1073167471350",
  appId: "1:1073167471350:web:7bfa4fb572923d1d97d2d7",
  measurementId: "G-T74BTW13F5",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const auth = getAuth(app);

const provider = new GoogleAuthProvider();

export { auth, provider };
