import React from "react";
import "../../../assets/css/sign-in.css";
import signInMainImg from "../../../assets/img/landing/sign-in-main-pic.png";
import tick from "../../../assets/img/tick-success.svg";
import { useNavigate } from "react-router-dom";
export const PasswordChangeDone = () => {
  const navigate = useNavigate();
  return (
    <div className="SignIn LayerUp">
      <div className="inner-work-sign-in grid grid-cols-2">
        <div className="left-side px-[26px] flex items-center justify-center flex-col">
          <img src={signInMainImg} alt="sign in main presentation" />
          <div className="presentation">
            <h1>Welcome to BetWin</h1>
            <p className="mt-4">
              Welcome to BetWin, where you've just made the perfect choice by
              signing up! We're thrilled to have you on board, and we're here to
              set you up for success.
            </p>
          </div>
        </div>
        <div className="right-side  px-8 py-10">
          <form
            action="#"
            className="flex flex-col items-center justify-center pb-14 h-full"
          >
            <img src={tick} alt="" className="w-[120px] h-[120px]" />
            <h1 className="mb-4 mt-6">Congratulation! </h1>
            <p className="text-[14px] font-arabicreg text-white">
              Your password reset successfully!
            </p>
            <button
              className="w-full rounded-lg mt-12"
              onClick={(e) => {
                e.preventDefault();
                navigate("/signin");
              }}
            >
              Return to Sign in
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};
