import React from "react";

// images
import button2 from "../../../assets/img/auth/apple.svg";
import button1 from "../../../assets/img/auth/facebook.svg";
import button from "../../../assets/img/auth/google.svg";
import button4 from "../../../assets/img/auth/send.svg";
import button3 from "../../../assets/img/auth/twitter.svg";
import button5 from "../../../assets/img/auth/whatsapp.svg";
// component
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ButtonLoader from "../../../components/ButtonLoader";
import { privateRequest } from "../../../config/axios.config";
export const ForgetPassword = () => {
  const [email, setEmail] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  const navigate = useNavigate();

  const changeHandler = e => {
    setEmail(e.target.value);
  }

  const submitHandler = e => {
    e.preventDefault()

    if (!email) return toast.error('Please enter your email')

    setIsLoading(true)

    privateRequest.post('user/password-reset', {
      email
    })
      .then(res => {
        setIsLoading(false)
        navigate("/otp", { state: { traceId: res.data?.data?.traceId, email: email } });
      })
      .catch(err => {
        setIsLoading(false)
        if (err.response?.data?.message) return toast.error(err.response.data.message)
        return toast.error('Something went wrong')
      })

  }
  return (
    <div className="SignIn LayerUp">
      <div className="inner-work-sign-in grid grid-cols-2">
        <div className="left-side  px-[26px] flex items-center justify-center flex-col">
          {/* <img src={signInMainImg} alt="sign in main presentation" /> */}
          <div className="presentation">
            <h1>Welcome to BetWin</h1>
            <p className="mt-4">
              Welcome to BetWin, where you've just made the perfect choice by
              signing up! We're thrilled to have you on board, and we're here to
              set you up for success.
            </p>
          </div>
        </div>
        <div className="right-side  px-8 py-10">
          <form onSubmit={submitHandler} className="flex flex-col h-full">
            <h1 className="mb-12">Reset Password</h1>
            <div className="input-wrapper mt-5 mb-6">
              <label htmlFor="email">Email / Phone Number</label>
              <input
                value={email}
                onChange={changeHandler}
                type="email"
                id="email"
                className="rounded-lg px-6 mt-3"
              />
            </div>

            <button
              type="submit"
              className="w-full relative rounded-lg mt-4 mb-9"
            >
              {isLoading ? <ButtonLoader /> : <p>Reset Password</p>}
            </button>
            <p className="dont-have-p">
              Already have an account! <Link to="/signin">Sign In</Link>
            </p>

           
          </form>
        </div>
      </div>
    </div>
  );
};
