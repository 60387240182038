import React, { useState } from "react";
import { LandingHeader } from "../components/landing/LandingHeader";
import { LandingFooter } from "../components/landing/LandingFooter";
import { Prize } from "../components/landing/Prize";
import dropdown from "../assets/img/contact/dropdown.svg";
import flag from "../assets/img/contact/flag.svg";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
export const Contact = () => {
  const [phone, setPhone] = useState("");
  // state work
  const [prizeState, setprizeState] = useState(true);
  return (
    <div>
      {/* {prizeState && <Prize setprizeState={setprizeState} />} */}
      <div className="Landing">
        <LandingHeader setprizeState={setprizeState} />
        <div className="flex flex-col mt-[140px] mb-[60px] items-center justify-center common-width-landing sm:mt-[50px] sm:mb-[40px]">
          <span className="text-[#9DF74A] text-[14px] font-arabicreg">
            Contact
          </span>
          <h1 className="text-[58px] md:text-[35px] sm:!text-[30px] mt-4 mb-6 text-center text-white font-arabiclight">
            <span className="text-[#9DF74A] font-arabicbold">Contact</span> us
          </h1>
        </div>
        <div className="p-12 common-width-landing bg-[#1A1A1B] border-[1px] border-[#3C403F] rounded-[12px] flex flex-col gap-6 md:p-6 sm:!py-8 ">
          <div>
            <label
              htmlFor="#"
              className="text-[20px] text-[#fff] font-arabicreg sm:text-[16px]"
            >
              Full Name
            </label>
            <input
              type="text"
              placeholder="Enter your full name"
              className="text-[18px] font-arabicreg text-[#AEB2B1] block w-full mt-4 bg-[#111010] border-[1px] border-[#3C403F] h-[50px] px-6 outline-none rounded-lg sm:text-[14px] sm:px-4"
            />
          </div>
          <div>
            <label
              htmlFor="#"
              className="text-[20px] text-[#fff] font-arabicreg  sm:text-[16px]"
            >
              Email Address
            </label>
            <input
              type="email"
              placeholder="Enter your email address"
              className="text-[18px] font-arabicreg text-[#AEB2B1] block w-full mt-4 bg-[#111010] border-[1px] border-[#3C403F] h-[50px] px-6 outline-none rounded-lg  sm:text-[14px] sm:px-4"
            />
          </div>

          <div className="relative">
            <label
              htmlFor="#"
              className="text-[20px] flex mb-4 w-full text-[#fff] font-arabicreg  sm:text-[16px]"
            >
              Your phone number
            </label>
            <PhoneInput
              countryCodeEditable={false}
              inputClass="!w-full !bg-[#111010] !text-white !border-[#3c403f] !border-l-0"
              buttonClass="!bg-[#252525]"
              searchClass="phone-search-field w-full !ml-0 !p-3 !bg-[#252525] "
              dropdownClass="!bg-[#1A1A1B]"
              country={"eg"}
              enableSearch={true}
              value={phone}
              onChange={(phone) => setPhone(phone)}
            />
          </div>
          <div>
            <label
              htmlFor="#"
              className="text-[20px] text-[#fff] font-arabicreg  sm:text-[16px]"
            >
              Subject
            </label>
            <input
              type="text"
              placeholder="Enter your subject"
              className="text-[18px] font-arabicreg text-[#AEB2B1] block w-full mt-4 bg-[#111010] border-[1px] border-[#3C403F] h-[50px] px-6 outline-none rounded-lg  sm:text-[14px] sm:px-4"
            />
          </div>
          <div>
            <label
              htmlFor="#"
              className="text-[20px] text-[#fff] font-arabicreg  sm:text-[16px]"
            >
              Message
            </label>
            <textarea
              name=""
              id=""
              cols="30"
              rows="10"
              placeholder="Enter your message"
              className="text-[18px] font-arabicreg text-[#AEB2B1] block w-full mt-4 bg-[#111010] border-[1px] border-[#3C403F] resize-none py-6 px-6 outline-none rounded-lg h-[158px]  sm:text-[14px] sm:p-4"
            ></textarea>
          </div>

          <div className="relative">
            <label
              htmlFor="#"
              className="text-[20px] text-[#fff] font-arabicreg  sm:text-[16px]"
            >
              Captcha
            </label>
            <input
              type="text"
              placeholder="Enter the captcha"
              className="text-[18px] font-arabicreg text-[#AEB2B1] block w-full mt-4 bg-[#111010] border-[1px] border-[#3C403F] h-[50px] px-6 pr-[160px] outline-none rounded-lg sm:text-[14px] sm:px-4  sm:pr-[100px]"
            />
            <span
              className="bg-[#1A1A1B] w-[153px] h-[50px] text-[#fff] text-[18px] italic flex items-center justify-center absolute bottom-0 right-0
             border-[1px] border-[#3C403F] rounded-tr-lg  rounded-br-lg sm:text-[14px] sm:w-[90px]"
            >
              77254
            </span>
          </div>
          <button className="text-[#111010] text-[16px] font-arabicbold bg-[#9DF74A] h-[50px] rounded-lg mt-[56px]">
            Submit Now
          </button>
        </div>

        <LandingFooter />
      </div>
    </div>
  );
};
