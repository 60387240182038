import img1 from "../assets/img/about/card1.svg";
import img2 from "../assets/img/about/card2.svg";
import img3 from "../assets/img/about/card3.svg";
import img4 from "../assets/img/about/card4.svg";

export const AboutCards = [
  {
    img: img1,
    heading: "Online Gaming Experts",
    desc: "Our team comprises seasoned professionals with extensive experience in the online gaming industry, ensuring that every aspect of your gaming experience is nothing short of exceptional.",
  },
  {
    img: img2,
    heading: "Blockchain Trailblazers",
    desc: "Armed with a deep understanding of blockchain technology, we lead the way in innovation and security, ensuring that your gaming experience is not only exciting but also secure.",
  },
  {
    img: img3,
    heading: "Land-based Casino Fans",
    desc: "Drawing inspiration from the world of traditional gambling, we seamlessly blend classic and modern gaming elements, creating an all-encompassing adventure for our players.",
  },
  {
    img: img4,
    heading: "Fintech Masters",
    desc: "Leveraging the power of financial technology, we've meticulously crafted secure and efficient financial systems within our platform, providing you with a seamless and secure gaming experience.",
  },
];
