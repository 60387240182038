import React, { useEffect, useState } from "react";
import { FaqQues } from "../components/faq/FaqQues";
import { LandingFooter } from "../components/landing/LandingFooter";
import { LandingHeader } from "../components/landing/LandingHeader";
import { FaqUtil } from "../utils/FaqUtil";

export const Faq = () => {
  // state work
  const [active, setactive] = useState(1);
  const [prizeState, setprizeState] = useState(false);
  const [activeFaq, setactiveFaq] = useState(-1);
  const [faqStateUtil, setfaqStateUtil] = useState(FaqUtil["general"]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="Landing">
      {/* {prizeState && <Prize setprizeState={setprizeState} />} */}
      <LandingHeader setprizeState={setprizeState} />
      <div className="mx-auto px-5 md:px-0 mt-20 sm:mt-[24px] lg:w-[95%]">
        <h1 className="text-[77px] font-arabicbold text-white text-center mb-[100px]  md:mb-[40px] sm:text-[28px] sm:!mb-[24px]">
          Our FAQs
        </h1>
        <div className="flex flex-col md:flex-row items-center gap-4 ">
          <button
            className={`flex-1 font-arabicmed text-[14px] bg-[#252525] py-3 w-full rounded-[8px] text-[#fff] border-[1px] border-[#9DF74A] ${
              active === 1 && "!bg-[#9DF74A] !text-[#111010]"
            }`}
            onClick={(e) => {
              setactive(1);
              setactiveFaq(-1);
              setfaqStateUtil(FaqUtil["general"]);
            }}
          >
            General FAQs
          </button>
          <button
            className={`flex-1 font-arabicmed text-[14px] bg-[#252525] py-3 w-full rounded-[8px] text-[#fff] border-[1px] border-[#9DF74A] ${
              active === 2 && "!bg-[#9DF74A] !text-[#111010]"
            }`}
            onClick={(e) => {
              setactive(2);
              setactiveFaq(-1);
              setfaqStateUtil(FaqUtil["player"]);
            }}
          >
            Player FAQs
          </button>
          <button
            className={`flex-1 font-arabicmed text-[14px] bg-[#252525] py-3 w-full rounded-[8px] text-[#fff] border-[1px] border-[#9DF74A] ${
              active === 3 && "!bg-[#9DF74A] !text-[#111010]"
            }`}
            onClick={(e) => {
              setactive(3);
              setactiveFaq(-1);
              setfaqStateUtil(FaqUtil["affiliate"]);
            }}
          >
            Affiliate FAQs
          </button>
          <button
            className={`flex-1 font-arabicmed text-[14px] bg-[#252525] py-3 w-full rounded-[8px] text-[#fff] border-[1px] border-[#9DF74A] ${
              active === 4 && "!bg-[#9DF74A] !text-[#111010]"
            }`}
            onClick={(e) => {
              setactive(4);
              setactiveFaq(-1);
              setfaqStateUtil(FaqUtil["staker"]);
            }}
          >
            Staker FAQs
          </button>
          <button
            className={`flex-1 font-arabicmed text-[14px] bg-[#252525] py-3 w-full rounded-[8px] text-[#fff] border-[1px] border-[#9DF74A] ${
              active === 5 && "!bg-[#9DF74A] !text-[#111010]"
            }`}
            onClick={(e) => {
              setactive(5);
              setactiveFaq(-1);
              setfaqStateUtil(FaqUtil["cash"]);
            }}
          >
            Cash Wallet FAQs
          </button>
        </div>

        <div className="faq-inner-work border-[1px] mt-8 border-[#3C403F] rounded-lg">
          {faqStateUtil.map((EachFaq, key) => (
            <FaqQues
              data={EachFaq}
              key={key}
              id={key}
              active={activeFaq}
              setactive={setactiveFaq}
            />
          ))}
        </div>
      </div>
      <LandingFooter />
    </div>
  );
};
