import React from "react";
import { LandingHeader } from "../components/landing/LandingHeader";
import { LandingFooter } from "../components/landing/LandingFooter";
import { Controller } from "react-scrollmagic";
import "../assets/css/landing/terms.css";
import { terms } from "../utils/terms";
import { Interweave } from "interweave";
import risk from "../assets/img/landing/risk.svg";

export const TermsConditions = () => {
  return (
    <Controller>
      <div className="Landing TermsConditions">
        <LandingHeader />

        <div className="main-section py-[120px] w-[1055px] mx-auto">
          <h1 className="text-center mb-8">Terms and Conditions</h1>
          <p className="w-[932px] text-center mx-auto">
            Please read these Terms and Conditions ("Terms") carefully before
            using BetWin services. By accessing or using our services, you
            agree to comply with and be bound by these Terms. If you do not
            agree to these Terms, please do not use our services
          </p>

          <ol className="flex flex-col gap-[60px] mt-[100px]">
            {terms.map((eachterm, outerKey) => (
              <li key={outerKey}>
                <h1 className="heading-li mb-8">{eachterm.heading}</h1>
                <ol className="flex flex-col gap-6 ml-3">
                  {eachterm.list.map((EachList, innerKey) => (
                    <li key={innerKey}>
                      <Interweave content={EachList} />
                    </li>
                  ))}
                </ol>
              </li>
            ))}
            <li className="outerlist">
              By using <span>BetWin services</span>, you acknowledge that you
              have read, <span>understood</span>, and agreed to these
              <span>Terms and Conditions</span>.
            </li>

            <li className="outerlist">
              <h1 className="flex items-center gap-3 mb-8">
                <img src={risk} alt="" />
                Risk Warning
              </h1>
              <p>
                BetWin service platform involves <span>risks associated</span>{" "}
                with gaming, cryptocurrencies, and{" "}
                <span>financial transactions</span>. Users should exercise
                caution and conduct their own research before participating.
                <span>BetWin</span> does not provide financial or investment
                advice. Users are <span>responsible</span> for their own
                decisions and actions on the platform.
              </p>
            </li>
          </ol>
        </div>

        <LandingFooter />
      </div>
    </Controller>
  );
};
