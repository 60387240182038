export const FaqUtil = {
  general: [
    {
      question: "What is BetWin789?",
      answer: `<div>
        <p>BetWin789 is your ultimate online gaming platform, offering a vast array of games and exciting opportunities for players, affiliates, and stakers.</p>
        </div>
        `,
    },
    {
      question: "Who are the owners of Betwin?",
      answer: `<div>
      <p >Betwin is the brainchild of a diverse and experienced team spanning various fields:</p>
      <ul>
            <li>
            <span>Online Gaming:</span> Profound expertise in the online gaming industry.
            </li>
            <li>
            <span>Blockchain:</span> A team well-versed in blockchain technology and its applications.
            </li>
            <li>
            <span>Land-Based Casino Operators:</span> Insights from professionals with experience in traditional casino operations.
            </li>
            <li>
            <span>Fintech:</span> Expertise in financial technology to ensure secure financial systems within the platform.
            </li>
      </ul>
      </div>
      `,
    },
    {
      question: "How large is the Betwin team?",
      answer: `<div>
        <p className="text-[#AEB2B1] text-[16px] font-arabicmed">Our team comprises over 100 professionals from diverse backgrounds, spanning Asia, Europe, and the Americas, bringing a wealth of skills and perspectives to the project.</p>
        </div>
        `,
    },
    {
      question: "How do I sign up for BetWin789?",
      answer: `<div>
          <p className="text-[#AEB2B1] text-[16px] font-arabicmed">Signing up is a breeze! Visit our website, click “Sign Up,” and follow the simple registration process. You’ll be ready to explore and enjoy our offerings in no time.</p>
          </div>
          `,
    },
    {
      question: "Is BetWin789 secure and fair?",
      answer: `<div>
            <p className="text-[#AEB2B1] text-[16px] font-arabicmed">Absolutely! Security and fairness are our top priorities. We implement stringent security measures to protect user data and ensure the fairness of our games through regular validation.</p>
            </div>
            `,
    },
    {
      question: "Can I play games for free on BetWin789.net?",
      answer: `<div>
              <p className="text-[#AEB2B1] text-[16px] font-arabicmed">Yes, we offer free sign-ups for players. You can explore and enjoy games without any cost.</p>
              </div>
              `,
    },
    {
      question: "Where can I find Betwin Telegram Channel?",
      answer: `<div>
                <p className="text-[#AEB2B1] text-[16px] font-arabicmed">Our Telegram Channel for staking is @betwin78988. <a href="#">Join the channel here.</a></p>
                </div>
                `,
    },
  ],

  player: [
    {
      question: "What types of games are available for players on BetWin789?",
      answer: `<div>
        <p>BetWin789 boasts a diverse selection of games across various genres and platforms, ensuring non-stop excitement for gamers of all kinds.</p>
        </div>
        `,
    },
    {
      question: "How can I enhance my gaming skills on BetWin789.net?",
      answer: `<div>
        <p>Access resources and tutorials on our platform to level up your gaming skills and connect with fellow gamers.</p>
        </div>
        `,
    },
    {
      question:
        "What rewards and bonuses can players expect on BetWin789.net?",
      answer: `<div>
        <p>Players can relish gaming bonuses, engaging competitions, and exclusive in-game rewards that recognize dedication and skill.</p>
        </div>
        `,
    },
    {
      question: "Is my gameplay on BetWin789 secure and fair?",
      answer: `<div>
        <p>Yes, your safety and fair play are paramount. We have stringent security measures in place to safeguard your data and ensure fair gaming.</p>
        </div>
        `,
    },
    {
      question: "What is GRR?",
      answer: `<div>
        <p>GRR stands for Gaming Revenue Rewards, a daily reward for stakers.
        </p>
        </div>
        `,
    },
    {
      question: "How does it work?",
      answer: `<div>
        <p>Stakeholders receive daily rewards as a token of appreciation for their participation.</p>
        </div>
        `,
    },
    {
      question: "What is GRR?",
      answer: `<div>
        <p>GRR, or Gaming Revenue Rewards, is calculated as total bets minus total wins.</p>
        </div>
        `,
    },
    {
      question: "Why is it important?",
      answer: `<div>
        <p>It reflects the money retained by the casino from players' wagers before covering any operational expenses.</p>
        </div>
        `,
    },
    {
      question: "What is NGR?",
      answer: `<div>
        <p>NGR, or Net Gaming Revenue, indicates the actual earnings of the casino after deducting all expenses from the total revenue.</p>
        </div>
        `,
    },
    {
      question: "Why is it significant?",
      answer: `<div>
        <p>It provides insight into the true financial performance of the casino.</p>
        </div>
        `,
    },
    {
      question: "What are GC?",
      answer: `<div>
        <p>GC, or BetWin Credits, are credits designed for gameplay within the gaming hub.</p>
        </div>
        `,
    },
    {
      question: "How can I use GC?",
      answer: `<div>
        <p>Utilize GC to enhance your gaming experience within the BetWin platform.</p>
        </div>
        `,
    },
  ],

  affiliate: [
    {
      question: "How can I become an affiliate with BetWin789.net?",
      answer: `<div>
        <p>Becoming an affiliate is a straightforward process. Sign up on our platform and explore high-commission reward opportunities.</p>
        </div>
        `,
    },
    {
      question: "What kind of commissions do affiliates earn?",
      answer: `<div>
        <p>Affiliates earn commissions based on player activity and can also earn commissions from referring lenders who deposit funds into the platform.</p>
        </div>
        `,
    },
    {
      question: "Are there minimum requirements to become an affiliate?",
      answer: `<div>
        <p>Yes, specific requirements apply to different affiliate levels. Requirements include the number of active players referred or the accumulation of stakes. See the affiliate ranks and their requirements below:</p>
        <h2>Junior Associate</h2>
        <ul>
            <li>
            Sponsor 5 active players with a total wager of $5 OR
            </li>
            <li>
            Accumulate a monthly group of stakes totaling $10,000 OR
            </li>
            <li>
            Have a personal stake of $1,000.
            </li>
        </ul>

<h2>Senior Partner</h2>
        <ul>
        <li>
        Lead a team of 5 leaders OR
        </li>
        <li>
        Accumulate a monthly group of stakes totaling $50,000 OR
        </li>
        <li>
        Have a personal stake of $10,000.
        </li>
    </ul>

    <h2>Managing Director</h2>
    <ul>
    <li>
    Lead a team of 5 team leaders OR
    </li>
    <li>
    Accumulate a monthly group of stakes totaling $100,000 OR
    </li>
    <li>
    Have a personal stake of $20,000.
    </li>
</ul>
        </div>
        `,
    },
    {
      question: "What kinds of commission rewards do affiliates earn?",
      answer: `<div>
        <p>Affiliates can earn various commission rewards, including referral rewards from the Gross Gaming Revenue (GGR) of their players, up to 40%. There are also rewards from staker’s stake amounts, up to 4%, and monthly royalty bonuses of up to 40% within the period of your stakers.</p>
        </div>
        `,
    },
    {
      question: "Are there any ranks of affiliates to maximize the commission?",
      answer: `<div>
        <p>Yes, BetWin789 has affiliate ranks based on your performance and achievements. Each rank has its own set of requirements, and achieving higher ranks can lead to greater commission rewards.</p>
        </div>
        `,
    },
    {
      question: "Can affiliate players without a rank earn commission rewards?",
      answer: `<div>
        <p>Yes, absolutely! Players without a rank can still earn:</p>
        <ul>
            <li>
            1% commission for referring a staker
            </li>
            <li>
            10% royalty bonus from stakers
            </li>
            <li>
            10% commission from their community of players
            </li>
        </ul>
        </div>
        `,
    },
  ],

  staker: [
    {
      question: "What is staking in BetWin789?",
      answer: `<div>
        <p>Staking in BetWin789.net involves depositing cryptocurrency assets into the platform to lend to players, with your assets locked in for a specified period.</p>
        </div>
        `,
    },
    {
      question: "What is the minimum deposit required for stakers?",
      answer: `<div>
        <p>The minimum deposit for stakers is $1,000 or its equivalent in fiat or crypto assets.</p>
        </div>
        `,
    },
    {
      question: "How are stakers rewarded?",
      answer: `<div>
        <p>Stakers receive monthly Gaming Revenue Rewards (GRR) based on the Gross Gaming Revenue (GGR) generated by the platform, with a minimum reward of 3% of the deposited amount.</h2>

        </div>
        `,
    },
    {
      question: "Can I terminate my staking before the lock-in period expires?",
      answer: `<div>
        <p>Yes, stakers can terminate their staking; however, there is a termination fee of 20% of the total deposited amount.</p>
        </div>
        `,
    },
    {
      question: "Is my staked asset secure?",
      answer: `<div>
        <p>Yes, staked assets are securely stored in an offline security asset fund wallet and can be verified on the blockchain at any time for transparency.</p>
        </div>
        `,
    },
  ],

  cash: [
    {
      question: "What is a Cash Wallet in Betwin?",
      answer: `<div>
        <p>The Cash Wallet in Betwin is an internal wallet where all your rewards and earnings are credited within the platform, serving as a digital account to manage your winnings.</p>
        </div>
        `,
    },

    {
      question: "Can I transfer the funds from my Cash Wallet to another user?",
      answer: `<div>
        <p>Yes, you can transfer funds from your available Cash Wallet balance to another user within the Betwin platform, enabling sharing of earnings and transactions with other users.</p>
        </div>
        `,
    },

    {
      question:
        "Can I use the funds in my Cash Wallet to play in the casino and for sports betting on Betwin?",
      answer: `<div>
        <p>Absolutely! You can utilize the funds stored in your Cash Wallet to participate in various activities on Betwin, including casino games and sports betting, ensuring convenient gaming and betting experiences within the platform.</p>
        </div>
        `,
    },

    {
      question: "How can I withdraw the funds from my available Cash Wallet?",
      answer: `<div>
        <p>Betwin provides options for withdrawal, including both crypto and fiat currencies. Crypto withdrawals typically take 10 minutes to 24 hours, depending on blockchain confirmation, while fiat withdrawals may take 1 to 24 hours, depending on the currency and Betwin’s partnerships with financial institutions. Withdrawal times can vary due to external factors, so check Betwin’s withdrawal guidelines for specifics and updates.</p>
        </div>
        `,
    },
  ],
};
