import React from "react";
import { Scene } from "react-scrollmagic";
import blurks from "../../assets/img/landing/blur-ks.png";
import keysolutionspider from "../../assets/img/landing/keysolutionspider.png";
import { KeySolutions } from "../../utils/KeySolutions";

export const KeySolution = () => {
  return (
<div className="ManagementSolution pt-[20px] sm:pt-[40px] md:pt-[60px] lg:pt-[100px]  LandingCompanyOverView PlayerExp PaymentService relative overflow-hidden">
      <img
        src={blurks}
        alt=""
        className="absolute -left-8 -bottom-32 glowing-image-side"
      />
      <div className="common-width-landing">
        <div className="presentation flex flex-col justify-center items-center text-center">
          <Scene triggerHook={0.8} reverse={false} classToggle={"scrollIn"}>
            <h1 className="mt-4 scrollOut mb-6 text-center mx-auto">
              Key Solutions
            </h1>
          </Scene>
          <Scene triggerHook={0.8} reverse={false} classToggle={"scrollIn"}>
            <p className="scrollOut w-[885px]">
              BetWin change's commitment to innovation and financial inclusion
              drives our mission to create an accessible and secure platform for
              users worldwide, where cash, cryptocurrencies, and gaming chips
              flow seamlessly across borders and blockchain networks.
            </p>
          </Scene>
        </div>

        <div className=" mt-16 items-center gap-10 relative z-10">
          <div className="cards-wrapper relative grid grid-cols-2 gap-6">
            <img
              src={keysolutionspider}
              alt=""
              className="absolute spider-img top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2  w-1/3 md:w-1/4 lg:w-2/6 sm:w-1/5 xs:w-full"
            />
            {KeySolutions.map((EachCard, key) => (
             <div
   key={`p_${key}`}
                className={`card !bg-[#252525] !border-[#9435E2] p-6 flex items-start gap-6 min-h-[132px] w-[443px] lg:w-[443px] md:w-full sm:w-full xs:w-full z-10 rounded-xl ${
                  (key + 1) % 2 === 0 && "!justify-self-end"
                } `}
>
                <Scene
                  triggerHook={0.8}
                  reverse={false}
                  classToggle={"scrollIn"}
                >
                  <img
                    src={EachCard.img}
                    alt=""
                    className="scrollOut !min-w-12 !min-h-12"
                  />
                </Scene>

                <div className="card-presentation">
                  <h1 className="mb-3 !text-[16px]">{EachCard.heading}</h1>
                  <p className="!text-[14px]">{EachCard.desc}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
