import React from "react";
import star from "../../assets/img/roadmap/star.svg";
import dise from "../../assets/img/roadmap/dise.svg";
export const PlaneBox = ({ EachCard }) => {
  return (
    <div className="bg-plan-box w-[333px] 1xl:w-[270px] 1xl:px-5 border-[1px] border-[#9AA1B3] hover:border-[#9DF74A] p-8 rounded-lg transition-all xlmed:!w-full">
      <img src={EachCard.img} alt="" />
      <h2 className="text-[18px] mt-6 font-arabicbold mb-3 text-[#9DF74A]">
        {EachCard.date}
      </h2>
      <h1 className="text-white text-[20px] mb-2 font-arabicbold">
        {EachCard.heading}
      </h1>
      <ul className="flex flex-col gap-[2px]">
        {EachCard.list.map((EachList) => (
          <li className="flex  gap-2 items-start text-[#AEB2B1] text-[16px] font-arabicreg 1xl:text-[13px]">
            <img src={star} alt="" className="translate-y-1" />
            {EachList}
          </li>
        ))}
      </ul>
    </div>
  );
};
