export const EarningPotentialsList = [
  {
    heading: "Affiliate Benefits",
    list: [
      {
        heading: "Player Rewards",
        desc: "Connect players with our platform, and you'll earn a share of the rewards they receive. The more players you bring in, the more you can earn.",
      },
      {
        heading: "Gaming Operator Partnerships",
        desc: "Collaborate with gaming operators within our network, and earn commissions for each successful partnership you help establish.",
      },
      {
        heading: "Investor Engagement ",
        desc: "Attract investors to BetWin, and you'll receive commissions based on their investments and activities within our ecosystem.",
      },
    ],
  },
  // {
  //   heading: "For Junket Operators",
  //   list: [
  //     {
  //       heading: "Streamlined Operations",
  //       desc: "Simplify and optimize your junket operations with our cutting edge tools and solutions.",
  //     },
  //     {
  //       heading: "Risk Mitigation",
  //       desc: "Enhance security measures and mitigate the risks to safeguard your assets and players.",
  //     },
  //     {
  //       heading: "Marketing and Engagement",
  //       desc: "Boost player engagement and attract high-value clients with our targeted marketing strategies.",
  //     },
  //     {
  //       heading: "Financial Solutions",
  //       desc: "Access innovative financial solutions to support your junket operations and maximize profitability.",
  //     },
  //   ],
  // },
];
