import React from "react";
import { Link } from "react-router-dom";
import { Scene } from "react-scrollmagic";
import blur2 from "../../assets/img/landing/blur-section-2.png";
import share from "../../assets/img/landing/share.svg";
import { PaymentServicesUtil } from "../../utils/PaymentServices";
export const LandingCompanyOverView = () => {
  return (
    <div
      className="ManagementSolution  LandingCompanyOverView PlayerExp PaymentService relative overflow-hidden pt-[120px] pb-[40px] md:pb-[40px] sm:pb-[60px] xs:pb-[50px]"
      id="company-overview"
    >
      <img
        src={blur2}
        alt=""
        className="absolute blur-cards top-60 w-[1000px] right-20 glowing-image"
      />
      <div className="common-width-landing">
        <div className="presentation flex flex-col justify-center items-center text-center">
          <Scene triggerHook={0.8} reverse={false} classToggle={"scrollIn"}>
            <span className="flex scrollOut items-center justify-center">
              About Us
            </span>
          </Scene>
          <Scene triggerHook={0.8} reverse={false} classToggle={"scrollIn"}>
            <h1 className="mt-4 scrollOut mb-6 text-center mx-auto">
              Our Company <span>Overview</span>
            </h1>
          </Scene>
          <Scene triggerHook={0.8} reverse={false} classToggle={"scrollIn"}>
            <p className="scrollOut w-[746px]">
              BetWin, your gateway to gaming excellence, innovation, and lasting
              success. We're more than a platform; we're a community of advanced
              gaming operators, savvy investors, and dedicated gamers.
            </p>
          </Scene>
        </div>

        <div className=" mt-16 items-center gap-10 relative z-10">
          <div className="cards-wrapper grid grid-cols-2 gap-6">
            {PaymentServicesUtil.map((EachCard, key) => (
              <div
                key={`p_${key}`}
                className={`card p-6 min-h-[270px] rounded-xl `}
              >
                <div className="top-area flex mb-6 items-start justify-between">
                  <Scene
                    triggerHook={0.8}
                    reverse={false}
                    classToggle={"scrollIn"}
                  >
                    <img src={EachCard.img} alt="" className="scrollOut" />
                  </Scene>
                  <Link to="/">
                    <img src={share} alt="" />
                  </Link>
                </div>

                <div className="card-presentation">
                  <h1 className="mb-3">{EachCard.heading}</h1>
                  <p>{EachCard.desc}</p>
                </div>
              </div>
            ))}

            {/* <div className="flex items-center justify-center">
              <Link to="/signup">
                <img src={signupBtn} alt="" width="80%" height="auto" />
              </Link>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};
