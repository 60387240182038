import "animate.css/animate.min.css";
import * as React from "react";
import * as ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "./index.css";
import { ForgetPassword } from "./pages/auth/landing/ForgetPassword";
import { Signin } from "./pages/auth/landing/Signin";
import { SignUp } from "./pages/auth/landing/SignUp";
import { Landing } from "./pages/Landing";
import { PrivacyPolicy } from "./pages/PrivacyPolicy";
import { TermsConditions } from "./pages/TermsConditions";

import { About } from "./pages/About";
import { CreateNewPassword } from "./pages/auth/landing/CreateNewPassword";
import { Otp } from "./pages/auth/landing/Otp";
import { PasswordChangeDone } from "./pages/auth/landing/PasswordChangeDone";
import { BusinessPartners } from "./pages/BusinessPartners";
import { Contact } from "./pages/Contact";
import { Exchange } from "./pages/Exchange";
import { Faq } from "./pages/Faq";
import { GameProviders } from "./pages/GameProviders";
import NotFound from "./pages/NotFound";
import { Roadmap } from "./pages/Roadmap";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Landing />,
  },
  {
    path: "/about",
    element: <About />,
  },
  {
    path: "/contact",
    element: <Contact />,
  },
  {
    path: "/roadmap",
    element: <Roadmap />,
  },

  {
    path: "/Faq",
    element: <Faq />,
  },
  {
    path: "/terms-conditions",
    element: <TermsConditions />,
  },
  {
    path: "/business-partners",
    element: <BusinessPartners />,
  },
  {
    path: "/exchange",
    element: <Exchange />,
  },
  {
    path: "/game-providers",
    element: <GameProviders />,
  },
  {
    path: "/privacy-policy",
    element: <PrivacyPolicy />,
  },
  {
    path: "/signup",
    element: <SignUp />,
  },
  {
    path: "/signin",
    element: <Signin />,
  },
  {
    path: "/forget-password",
    element: <ForgetPassword />,
  },

  {
    path: "/otp",
    element: <Otp />,
  },
  {
    path: "/password-change-done",
    element: <PasswordChangeDone />,
  },
  {
    path: "/password-create",
    element: <CreateNewPassword />,
  },
  {
    path: "*",
    element: <NotFound />,
  }
]);

ReactDOM.createRoot(document.getElementById("root")).render(
  <>
    <RouterProvider router={router} />
    <ToastContainer />
  </>
);
