import React from "react";
import blurop1 from "../../assets/img/landing/blur-op1.png";
import blurop2 from "../../assets/img/landing/blur-op2.png";

import { Splide, SplideSlide } from "@splidejs/react-splide";
import { Scene } from "react-scrollmagic";
import {
  gmOperators,
  gmProviders,
  gpCards,
  sgCards,
} from "../../utils/Partners";

// Default theme
import "@splidejs/react-splide/css";

// or other themes
import "@splidejs/react-splide/css/sea-green";
import "@splidejs/react-splide/css/skyblue";

// or only core styles
import "@splidejs/react-splide/css/core";

export const Partners = () => {
  return (
    <div
      className="ManagementSolution  pt-[50px] pb-[40px] md:pb-[30px] sm:pb-[30px] xs:pb-[20px]   !bg-[transparent] BettingFeature Partners relative overflow-hidden"
      id="partners"
    >
      <img
        src={blurop1}
        alt=""
        className="absolute -left-8 -top-16 glowing-image-side"
      />
      <img
        src={blurop2}
        alt=""
        className="absolute -right-8 -bottom-0 glowing-image-side"
      />
      <div className="common-width-landing">
        <div className="presentation flex flex-col justify-center items-center text-center">
          <Scene triggerHook={0.8} reverse={false} classToggle={"scrollIn"}>
            <span className="flex items-center justify-center scrollOut">
              Partners
            </span>
          </Scene>
          <Scene triggerHook={0.8} reverse={false} classToggle={"scrollIn"}>
            <h1 className="mt-4 mb-6 w-full scrollOut">
              Our <span>Partners</span>
            </h1>
          </Scene>
          <Scene triggerHook={0.8} reverse={false} classToggle={"scrollIn"}>
            <p className="scrollOut w-[839px]">
              With our technology, you will be able to succeed in your iGaming
              venture. Having built a global client base, we've established
              ourselves as a leader in the iGaming software industry.
            </p>
          </Scene>
        </div>

        <div className="slide-wrapper mt-16 mb-16 relative">
          <Scene triggerHook={0.8} reverse={false} classToggle={"scrollIn"}>
            <h1 className="scrollOut">Liquidity Providers</h1>
          </Scene>
          <Splide
            className="mt-8 mb-16  SliderAreaFirst"
            options={{
              gap: 10,
              pagination: false,
              perPage: 4,
              breakpoints: {
                900: {
                  perPage: 3,
                },
                700: {
                  perPage: 2,
                },
              },
              arrows: false,
              interval: 3000, 
              loop: true,
              autoplay: true,
              pauseOnHover: false,
              resetProgress: false,
              cover: true,
            }}
          >
            {gpCards.map((EachImage, key) => (
              <SplideSlide key={`ppss_${key}`}>
                <div className="card ">
                  <Scene
                    triggerHook={0.8}
                    reverse={false}
                    classToggle={"scrollIn"}
                  >
                    <div className="h-[126px] flex items-center scrollOut justify-center">
                      <img
                        src={EachImage.img}
                        alt={`slider ${key + 1}`}
                        className="rounded-tr-lg  rounded-tl-lg "
                      />
                    </div>
                  </Scene>
                  <div className="presentation p-6 rounded-bl-lg rounded-br-lg">
                    <h1 className="mb-2 text-center">{EachImage.heading}</h1>
                  </div>
                </div>
              </SplideSlide>
            ))}
          </Splide>
        </div>

        <div className="slide-wrapper mt-16 mb-16 relative">
          <Scene triggerHook={0.8} reverse={false} classToggle={"scrollIn"}>
            <h1 className="scrollOut">Gaming Operators</h1>
          </Scene>
          <Splide
            className="mt-8 mb-16 SliderAreaFirst"
            options={{
              gap: 10,
              pagination: false,
              perPage: 4,
              breakpoints: {
                900: {
                  perPage: 3,
                },
                700: {
                  perPage: 2,
                },
              },
              arrows: false,
              interval: 3000, 
              loop: true,
              autoplay: true,
              pauseOnHover: false,
              resetProgress: false,
              cover: true,
            }}
          >
            {gmOperators.map((EachImage, key) => (
              <SplideSlide key={`ppss_${key}`}>
                <div className="card ">
                  <Scene
                    triggerHook={0.8}
                    reverse={false}
                    classToggle={"scrollIn"}
                  >
                    <div className="h-[126px] flex items-center scrollOut justify-center">
                      <img
                        src={EachImage.img}
                        alt={`slider ${key + 1}`}
                        className="rounded-tr-lg  rounded-tl-lg "
                      />
                    </div>
                  </Scene>
                  <div className="presentation p-6 rounded-bl-lg rounded-br-lg">
                    <h1 className="mb-2 text-center">{EachImage.heading}</h1>
                  </div>
                </div>
              </SplideSlide>
            ))}
          </Splide>
        </div>

        <div className="slide-wrapper mt-16 mb-16 relative">
          <Scene triggerHook={0.8} reverse={false} classToggle={"scrollIn"}>
            <h1 className="scrollOut">Chain Providers</h1>
          </Scene>
          <Splide
            className="mt-8 mb-16 SliderAreaFirst"
            options={{
              gap: 10,
              pagination: false,
              perPage: 4,
              breakpoints: {
                900: {
                  perPage: 3,
                },
                700: {
                  perPage: 2,
                },
              },
              arrows: false,
              interval: 3000, 
              loop: true,
              autoplay: true,
              pauseOnHover: false,
              resetProgress: false,
              cover: true,
            }}
          >
            {sgCards.map((EachImage, key) => (
              <SplideSlide key={`ppss_${key}`}>
                <div className="card ">
                  <Scene
                    triggerHook={0.8}
                    reverse={false}
                    classToggle={"scrollIn"}
                  >
                    <div className="h-[126px] flex items-center scrollOut justify-center">
                      <img
                        src={EachImage.img}
                        alt={`slider ${key + 1}`}
                        className="rounded-tr-lg  rounded-tl-lg "
                      />
                    </div>
                  </Scene>
                  <div className="presentation p-6 rounded-bl-lg rounded-br-lg">
                    <h1 className="mb-2 text-center">{EachImage.heading}</h1>
                  </div>
                </div>
              </SplideSlide>
            ))}
          </Splide>
        </div>

        <div className="slide-wrapper pt-5  relative" id="game-provider">
          <Scene triggerHook={0.8} reverse={false} classToggle={"scrollIn"}>
            <h1 className="scrollOut">Gaming Providers</h1>
          </Scene>
          <Splide
            className="mt-8 mb-16 SliderAreaFirst"
            options={{
              gap: 10,
              pagination: false,
              perPage: 4,
              breakpoints: {
                900: {
                  perPage: 3,
                },
                700: {
                  perPage: 2,
                },
              },
              arrows: false,
              interval: 3000, 
              loop: true,
              autoplay: true,
              pauseOnHover: false,
              resetProgress: false,
              cover: true,
            }}
          >
            {gmProviders.map((EachImage, key) => (
              <SplideSlide key={`ppss_${key}`}>
                <div className="card ">
                  <Scene
                    triggerHook={0.8}
                    reverse={false}
                    classToggle={"scrollIn"}
                  >
                    <div className="h-[126px] flex items-center scrollOut justify-center">
                      <img
                        src={EachImage.img}
                        alt={`slider ${key + 1}`}
                        className="rounded-tr-lg  rounded-tl-lg "
                      />
                    </div>
                  </Scene>
                  <div className="presentation p-6 rounded-bl-lg rounded-br-lg">
                    <h1 className="mb-2 text-center">{EachImage.heading}</h1>
                  </div>
                </div>
              </SplideSlide>
            ))}
          </Splide>
        </div>
        
      </div>
    </div>
  );
};
