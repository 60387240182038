import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "../../../assets/css/sign-in.css";
import ButtonLoader from "../../../components/ButtonLoader";
import { privateRequest } from "../../../config/axios.config";

export const Otp = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [code, setCode] = React.useState(["", "", "", "", "", ""]);
  const [isLoading, setIsLoading] = React.useState(false);
  const inputRefs = useRef([]);

  const [traceId, setTraceId] = useState(state?.traceId);
  const [email, setEmail] = useState(state?.email);
  const codeChangeHandler = (e, index) => {
    const value = e.target.value;
    const newCode = [...code];
    newCode[index] = value.slice(0, 1); // Ensure only one character

    // Move to the next input field if the current input has a value
    if (value && index < 5) {
      inputRefs.current[index + 1].focus();
    } else if (!value && index > 0) {
      inputRefs.current[index - 1].focus();
    }

    setCode(newCode);
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && !code[index] && index > 0) {
      const newCode = [...code];
      newCode[index - 1] = "";
      setCode(newCode);
      inputRefs.current[index - 1].focus();
    }
  };

  const handlePaste = (e) => {
    const pasteData = e.clipboardData.getData("text").slice(0, 6).split("");
    const newCode = [...code];
    pasteData.forEach((char, index) => {
      if (index < 6) {
        newCode[index] = char;
      }
    });
    setCode(newCode);
    if (pasteData.length < 6) {
      inputRefs.current[pasteData.length].focus();
    } else {
      inputRefs.current[5].focus();
    }
  };

  useEffect(() => {
    if (!traceId) navigate('/forget-password');
    inputRefs.current[0].focus();

  }, [traceId, navigate])

  const verifyOtp = (e) => {
    e.preventDefault()

    if (code?.join("").length < 6) return toast.error('Please enter the code')

    setIsLoading(true)

    privateRequest.post('user/password-reset/verify', {
      traceId,
      code: code.join('')
    })
      .then(res => {
        setIsLoading(false)
        navigate("/password-create", { state: { token: res.data?.data?.token } });
      })
      .catch(err => {
        setIsLoading(false)
        console.log(err, 'err')
        if (err.response?.data?.message) return toast.error(err.response.data.message)
        return toast.error('Something went wrong')
      })
  }

  const handleResend = async () => {
    privateRequest.post('user/password-reset', {
      email
    })
      .then(res => {
        setIsLoading(false);
        toast.success(res.data?.message);
        setTraceId(res.data?.data?.traceId);
        setEmail(email);
      })
      .catch(err => {
        setIsLoading(false)
        if (err.response?.data?.message) return toast.error(err.response.data.message)
        return toast.error('Something went wrong')
      })
  }

  return (
    <div className="SignIn LayerUp">
      <div className="inner-work-sign-in grid grid-cols-2">
        <div className="left-side px-[26px] flex items-center justify-center flex-col">

          <div className="presentation">
            <h1>Welcome to BetWin</h1>
            <p className="mt-4">
              Welcome to BetWin, where you've just made the perfect choice by
              signing up! We're thrilled to have you on board, and we're here to
              set you up for success.
            </p>
          </div>
        </div>
        <div className="right-side mt-10 px-8 py-10">
          <form onSubmit={verifyOtp} className="flex flex-col h-full">
            <h1 className="mb-12">OTP Verification </h1>
            <div className="input-wrapper grid grid-cols-6 gap-3">
              {code.map((digit, index) => (
                <input
                  key={index}
                  type="text"
                  value={digit}
                  onChange={(e) => codeChangeHandler(e, index)}
                  onKeyDown={(e) => handleKeyDown(e, index)}
                  onPaste={handlePaste}
                  maxLength={1}
                  ref={(el) => (inputRefs.current[index] = el)}
                  className="rounded-lg text-center !text-[20px] !text-white !w-[50px] !h-[50px]"
                />
              ))}
            </div>

            <button className="w-full rounded-lg mt-8 mb-8">
              {isLoading ? <ButtonLoader /> : "Verify"}
            </button>
            <p className="dont-have-p">
              Didn’t received code?{" "}
              <a
                href="#"
                className="bg-transparent"
                onClick={handleResend}
              >
                Resend
              </a>
            </p>
          </form>
        </div>
      </div>
    </div>
  );
};
