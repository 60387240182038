import React, { useState } from "react";
import { LandingHeader } from "../components/landing/LandingHeader";
import { LandingFooter } from "../components/landing/LandingFooter";
import { Prize } from "../components/landing/Prize";
import plane from "../assets/img/roadmap/rocket.svg";
import { PlaneBox } from "../components/roadmap/PlaneBox";
import { RoadMapUtil } from "../utils/RoadMapUtil";
export const Roadmap = () => {
  // state work
  const [prizeState, setprizeState] = useState(true);
  return (
    <div>
      {/* {prizeState && <Prize setprizeState={setprizeState} />} */}
      <div className="Landing roadmapwrapper">
        <LandingHeader setprizeState={setprizeState} />
        <div className="flex flex-col mt-[140px] mb-[80px] items-center justify-center common-width-landing sm:mt-[50px] sm:mb-[40px]">
          <span className="text-[#9DF74A] text-[14px] font-arabicreg">
            Roadmap
          </span>
          <h1 className="text-[58px] md:text-[35px] sm:!text-[30px] mt-4 mb-6 text-center text-white font-arabiclight">
            Our <span className="text-[#9DF74A] font-arabicbold">Roadmap</span>
          </h1>
          <p className="w-[711px] text-center  text-[#AEB2B1] text-[20px] font-arabicmed md:w-full md:text-[16px] sm:!text-[14px]">
            BetWin offers a comprehensive service platform designed to cater to
            the diverse needs of players, affiliates, gaming operators and
            stakers.
          </p>
        </div>
        <div className="plane-img mt-[60px] h-[1570px] xlmed:mb-10  xlmed:h-[unset] mb-60 ">
          <img src={plane} alt="" className="w-full xlmed:hidden" />
          <div className="plane-area common-width-landing relative xlmed:grid xlmed:grid-cols-3 gap-10  lgmed:gap-5 lgmed:!grid-cols-2 sm:!flex sm:!flex-col">
            {RoadMapUtil.map((EachCard) => (
              <div className={EachCard.style}>
                <img
                  src={EachCard.icon}
                  alt=""
                  className={EachCard.active && "blinkingAnimation"}
                />
                <PlaneBox EachCard={EachCard} />
              </div>
            ))}
          </div>
        </div>

        <LandingFooter />
      </div>
    </div>
  );
};
