import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Controller } from 'react-scrollmagic';
import four_zero_four from '../assets/img/404_image.png';
import { LandingFooter } from '../components/landing/LandingFooter';
import { LandingHeader } from '../components/landing/LandingHeader';


export default function NotFound() {
    // state work
    const [prizeState, setprizeState] = useState(true);
    return (
        <>
            <Controller>
                {/* {prizeState && <Prize setprizeState={setprizeState} />} */}
                <div className="Landing">
                    <LandingHeader />
                    <div className="flex justify-center h-screen items-center gap-6">
                        <div>
                            <img src={four_zero_four} alt="404 not found" width={220} />
                        </div>
                        <div className='leading-10'>
                            <h3 className='text-[60px] text-[#0DC10D] font-arabicbold'>404</h3>
                            <h3 className='text-white text-lg pt-5 font-arabicmed'>Oops! Page Not Found</h3>
                            <p className='text-[#9B9B9B] font-arabiclight'>It looks like you've ventured off the beaten path. The page you were looking for doesn't exist.</p>
                            <Link to="/" className='bg-[#0DC10D] text-white px-5 py-2 rounded'>
                                Go back!
                            </Link>
                        </div>
                    </div>
                    <LandingFooter />
                </div>
            </Controller>
        </>
    )
}
