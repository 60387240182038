import img1 from "../assets/img/roadmap/first.svg";
import img2 from "../assets/img/roadmap/dise.svg";
import img3 from "../assets/img/roadmap/third.svg";
import img4 from "../assets/img/roadmap/fourth.svg";
import img5 from "../assets/img/roadmap/five.svg";
import img6 from "../assets/img/roadmap/sixth.svg";

import one from "../assets/img/roadmap/one.svg";
import two from "../assets/img/roadmap/two.svg";
import three from "../assets/img/roadmap/three.svg";
import four from "../assets/img/roadmap/four.svg";
import five from "../assets/img/roadmap/five1.svg";
import six from "../assets/img/roadmap/six.svg";

export const RoadMapUtil = [
  {
    active: true,
    style:
      "flex flex-col-reverse xlmed:flex-col items-center gap-5 absolute left-0 -bottom-4 xlmed:static",
    icon: one,
    img: img1,
    date: "October 2023",
    heading: "Project & Concept Creation.",
    list: [
      "Devs. Team onboarding",
      "Architecture Planning",
      "Design and Development",
    ],
  },
  {
    active: true,
    style:
      "flex flex-col items-center gap-5 absolute left-[15%] -top-16 xlmed:static",
    icon: two,
    img: img2,
    date: "November 2023",
    heading: "Pilot Launch",
    list: [
      "V.1 Platform Live",
      "Affiliate and Staking",
      "Gaming Master License Process.",
    ],
  },
  {
    active: true,
    style:
      "flex flex-col-reverse xlmed:flex-col items-center gap-5 absolute left-[27%] bottom-2 xlmed:static 2minxl:bottom-4",
    icon: three,
    img: img3,
    date: "December 2023",
    heading: "New Update",
    list: ["P2P and Swap", "Online Casino and Sports betting", "Partnership"],
  },
  {
    style:
      "flex flex-col items-center gap-5 absolute left-[42%] -top-28 xlmed:static 2minxl:-top-32",
    icon: four,
    img: img4,
    date: "",
    heading: "Q1 - 2024",
    list: [
      "BetWin Chain Blockchain Protocol Creation",
      "Expansion of land based and online operation",
      "BetwinLab (Tech Company)",
      "Launch of Betwin Exchange (DEX/CEX)",
      "Mobile App",
    ],
  },
  {
    style:
      "flex flex-col-reverse items-center gap-5 absolute left-[53.5%] bottom-[90px] xlmed:static xlmed:flex-col 2minxl:bottom-[100px] ",
    icon: five,
    img: img5,
    date: "",
    heading: "Q2 - 2024",
    list: [
      "BetwinChain Whitepaper Release",
      "Website Launch",
      "Public Coin offering and partnership",
      "GameFi App",
    ],
  },
  {
    style:
      "flex flex-col items-center gap-28 absolute right-10 -bottom-28  1xl:-bottom-18 xlmed:static xlmed:gap-5 1by2xl:!-bottom-32 2minxl:!-bottom-30 ",
    icon: six,
    img: img6,
    date: "",
    heading: "Q3 & Q4 - 2024",
    list: [
      "Integrated Gaming Hub",
      "Partnership and Acquisition",
      "Betwin protocol Launch (Beta)",
    ],
  },
];
