import { Interweave } from "interweave";
import React from "react";
import arrow from "../../assets/img/faq/arrow.svg";
export const FaqQues = ({ data, id, active, setactive }) => {
  return (
    <div
      className={`${
        active === id && "bg-[#252525]"
      } FaqQues border-t-[1px] border-t-[#3C403F] ${
        id === 0 && "!border-t-[0px] "
      }`}
    >
      <div
        className={`flex cursor-pointer items-center justify-between px-6 py-8 bg-[#1A1A1B] ${
          active === id && "!pb-6 !bg-[transparent]"
        } ${id === 0 && "rounded-tl-lg rounded-tr-lg"} `}
        onClick={(e) => {
          setactive(id);
        }}
      >
        <h1 className="text-[18px] sm:text-[16px] font-arabicmed text-white">
          {data.question}
        </h1>
        <img
          src={arrow}
          alt=""
          className={`${active === id && "rotate-180"}`}
        />
      </div>
      {active === id && (
        <div className="pb-8 px-6">
          <Interweave content={data.answer} />
        </div>
      )}
    </div>
  );
};
