import img1 from "../assets/img/landing/mission-overview.svg";
import img2 from "../assets/img/landing/vision-overview.svg";
import img3 from "../assets/img/landing/services-overview.svg";

export const PaymentServicesUtil = [
  {
    img: img1,
    heading: "Our Mission",
    desc: "Our mission at BetWin is to empower individuals and businesses within the gaming industry to achieve sustainable success. As a gaming ecosystem, we aim to enable players to excel, operators to expand their horizons, and investors to make educated decisions.",
  },
  {
    img: img2,
    heading: "Our Vision",
    desc: "BetWin envisions a future where gaming transcends mere pastime to become a platform for personal growth, innovation, and financial prosperity. We strive to be the leading catalyst for positive change in the gaming industry, promoting an environment where all stakeholders can succeed.",
  },
  {
    img: img3,
    heading: "Our Services",
    desc: "BetWin is your ultimate one-stop platform, offering an array of services meticulously tailored to players, gaming operators, junket operators, and investors. We're dedicated to paving sustainable paths to success, whether you're here to play, market, or invest in the vibrant world of gaming.",
  },
];
