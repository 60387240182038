import img1 from "../assets/img/landing/gc1.png";
import img2 from "../assets/img/landing/gc2.png";
import img3 from "../assets/img/landing/gc3.png";
import img4 from "../assets/img/landing/gc4.png";

import go1 from "../assets/img/landing/go1.png";
import go2 from "../assets/img/landing/go2.png";
import go3 from "../assets/img/landing/go3.png";
import go4 from "../assets/img/landing/go4.png";

import ch1 from "../assets/img/landing/ch1.png";
import ch2 from "../assets/img/landing/ch2.png";
import ch3 from "../assets/img/landing/ch3.png";
import ch4 from "../assets/img/landing/ch4.png";

import gp1 from "../assets/img/landing/gp1.png";
import gp2 from "../assets/img/landing/gp2.png";
import gp3 from "../assets/img/landing/gp3.png";
import gp4 from "../assets/img/landing/gp4.png";

import gn1 from "../assets/img/landing/gn1.png";
import gn2 from "../assets/img/landing/gn2.png";
import gn3 from "../assets/img/landing/gn3.png";
import gn4 from "../assets/img/landing/gn4.png";
import gn5 from "../assets/img/landing/gn5.png";
import gn6 from "../assets/img/landing/gn6.png";
import gn7 from "../assets/img/landing/gn7.png";
import gn8 from "../assets/img/landing/gn8.png";
import gn9 from "../assets/img/landing/gn9.png";
import gn10 from "../assets/img/landing/gn10.png";
import gn11 from "../assets/img/landing/gn11.png";
import gn12 from "../assets/img/landing/gn12.png";
import gn13 from "../assets/img/landing/gn13.png";
import gn14 from "../assets/img/landing/gn14.png";
import gn15 from "../assets/img/landing/gn15.png";
import gn16 from "../assets/img/landing/gn16.png";
import gn17 from "../assets/img/landing/gn17.png";
import gn18 from "../assets/img/landing/gn18.png";
import gn19 from "../assets/img/landing/gn19.png";
import gn20 from "../assets/img/landing/gn20.png";
import gn21 from "../assets/img/landing/gn21.png";
import gn22 from "../assets/img/landing/gn22.png";
import gn23 from "../assets/img/landing/gn23.png";
import gn24 from "../assets/img/landing/gn24.png";
import gn25 from "../assets/img/landing/gn25.png";
import gn26 from "../assets/img/landing/gn26.png";
import gn27 from "../assets/img/landing/gn27.png";
import gn28 from "../assets/img/landing/gn28.png";
import gn29 from "../assets/img/landing/gn29.png";
import gn30 from "../assets/img/landing/gn30.png";
import gn31 from "../assets/img/landing/gn31.png";
import gn32 from "../assets/img/landing/gn32.png";
import gn33 from "../assets/img/landing/gn33.png";
import gn34 from "../assets/img/landing/gn34.png";
import gn35 from "../assets/img/landing/gn35.png";
import gn36 from "../assets/img/landing/gn36.png";
import gn37 from "../assets/img/landing/gn37.png";
import gn38 from "../assets/img/landing/gn38.png";
import gn39 from "../assets/img/landing/gn39.png";
import gn40 from "../assets/img/landing/gn40.png";
import gn41 from "../assets/img/landing/gn41.png";
import gn42 from "../assets/img/landing/gn42.png";
import gn43 from "../assets/img/landing/gn43.png";
import gn44 from "../assets/img/landing/gn44.png";
import gn45 from "../assets/img/landing/gn45.png";
import gn46 from "../assets/img/landing/gn46.png";
import gn47 from "../assets/img/landing/gn47.png";
import gn48 from "../assets/img/landing/gn48.png";
import gn49 from "../assets/img/landing/gn49.png";

export const gpCards = [
  {
    img: img1,
    heading: "Binance ",
  },

  {
    img: img2,
    heading: "Gate.io",
  },

  {
    img: img3,
    heading: "Uniswap",
  },

  {
    img: img4,
    heading: "WazirX",
  },
];
export const sgCards = [
  {
    img: ch1,
    heading: "Bitcoin",
  },
  {
    img: ch2,
    heading: "Ethereum",
  },
  {
    img: ch3,
    heading: "BNB Chain",
  },
  {
    img: ch4,
    heading: "Polygon",
  },
];
export const gmProviders = [
  {
    img: gp1,
    heading: "Play’n GO",
  },

  {
    img: gp2,
    heading: "Pragmatic Play",
  },
  {
    img: gp3,
    heading: "BGaming",
  },
  {
    img: gp4,
    heading: "Push Gaming",
  },

  {
    img: gn1,
    heading: "AMATIC Industries",
  },
  {
    img: gn2,
    heading: "Evolution",
  },
  {
    img: gn3,
    heading: "Pragmatic",
  },
  {
    img: gn4,
    heading: "Pocket Games Soft",
  },
  {
    img: gn5,
    heading: "Supribe",
  },
  {
    img: gn6,
    heading: "3 Oaks",
  },
  {
    img: gn7,
    heading: "Netent",
  },
  {
    img: gn8,
    heading: "Red Tiger",
  },
  {
    img: gn9,
    heading: "Greentube",
  },
  {
    img: gn10,
    heading: "Igrosoft",
  },
  {
    img: gn11,
    heading: "Spinomenal",
  },

  {
    img: gn12,
    heading: "Playson",
  },
  {
    img: gn13,
    heading: "Zeus Gaming Club",
  },
  {
    img: gn14,
    heading: "Platipus",
  },
  {
    img: gn15,
    heading: "Amusnet",
  },

  {
    img: gn16,
    heading: "KA Gaming",
  },
  {
    img: gn17,
    heading: "Ezugi",
  },
  {
    img: gn18,
    heading: "BTG",
  },
  {
    img: gn19,
    heading: "Yggdrasil",
  },
  {
    img: gn20,
    heading: "Nolimit City",
  },
  {
    img: gn21,
    heading: "Gamzix",
  },

  {
    img: gn22,
    heading: "BF Games",
  },
  {
    img: gn23,
    heading: "Concept Gaming",
  },

  {
    img: gn24,
    heading: "Fazi",
  },
  {
    img: gn25,
    heading: "Golden Race",
  },
  {
    img: gn26,
    heading: "Aviatrix",
  },
  {
    img: gn27,
    heading: "B Gaming",
  },
  {
    img: gn28,
    heading: "CT Interactive",
  },

  {
    img: gn29,
    heading: "Felix Gaming",
  },
  {
    img: gn30,
    heading: "Grande Games",
  },
  {
    img: gn31,
    heading: "Amigo Gaming",
  },
  {
    img: gn32,
    heading: "BluePrint",
  },

  {
    img: gn33,
    heading: "Fugaso",
  },
  {
    img: gn34,
    heading: "Games Global",
  },
  {
    img: gn35,
    heading: "Belata Games",
  },
  {
    img: gn36,
    heading: "Boming Games",
  },
  {
    img: gn37,
    heading: "ELK Studios",
  },
  {
    img: gn38,
    heading: "Foxium",
  },
  {
    img: gn39,
    heading: "Habanero",
  },

  {
    img: gn40,
    heading: "Bet Games",
  },
  {
    img: gn41,
    heading: "BoldPlay",
  },
  {
    img: gn42,
    heading: "Gameart",
  },
  {
    img: gn43,
    heading: "Betradar",
  },
  {
    img: gn44,
    heading: "Caleta",
  },
  {
    img: gn45,
    heading: "Gamebeat",
  },
  {
    img: gn46,
    heading: "Betsoft ",
  },
  {
    img: gn47,
    heading: "Jet Games",
  },
  {
    img: gn48,
    heading: "Netgame",
  },
  {
    img: gn49,
    heading: "Onlyplay",
  },
];

export const gmOperators = [
  {
    img: go1,
    heading: "BC Game",
  },
  {
    img: go2,
    heading: "Crashino",
  },
  {
    img: go3,
    heading: "Winz",
  },
  {
    img: go4,
    heading: "Wildcoins",
  },
];
