import img1 from "../assets/img/landing/s1.png";
import img2 from "../assets/img/landing/s2.png";
import img3 from "../assets/img/landing/s3.png";
import img4 from "../assets/img/landing/s4.png";

export const firstList = [
  {
    img: img1,
    heading: "Invest with Confidence",
    para: "Join the ranks of successful investors in the thriving gaming industry with BetWin. Our expert team provides the guidance, due diligence, and market analysis you need to make informed investment decisions. With our support, step into the gaming world with confidence, knowing your investments are backed by industry expertise.",
  },
  {
    img: img2,
    heading: "Diverse Opportunities",
    para: "BetWin offers a wide range of investment options within the dynamic gaming ecosystem. Whether you're interested in backing game development studios, eSports organizations, or other gaming-related ventures, we have opportunities that align with your investment goals. Explore the diversity of the gaming industry and uncover the most promising prospects for your portfolio.",
  },
  {
    img: img3,
    heading: "Sustainable Growth",
    para: "Invest in the future of gaming, where the potential for sustainable growth knows no bounds. The gaming industry is continuously evolving, presenting exciting opportunities for investors. Our team of industry experts is here to guide you through the ever-changing landscape, ensuring your investments are poised for long-term success.",
  },
  {
    img: img4,
    heading: "Network & Collaborate",
    para: "Connect with gaming industry leaders and like-minded investors within the BetWin community. Collaborate on exciting ventures, share insights, and stay ahead of market trends. Our network is your gateway to building valuable connections, fostering collaborations, and remaining at the forefront of the gaming industry.",
  },
];
