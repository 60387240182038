import React from "react";
import betwinProvide from "../../assets/img/landing/betwin-provide.png";
import provider1 from "../../assets/img/landing/provider1.svg";
import provider2 from "../../assets/img/landing/provider2.svg";
import provider3 from "../../assets/img/landing/provider3.svg";
import provider4 from "../../assets/img/landing/provider4.svg";
import provider5 from "../../assets/img/landing/provider5.svg";
import provider6 from "../../assets/img/landing/provider6.svg";
import provider7 from "../../assets/img/landing/provider7.svg";
import { Scene } from "react-scrollmagic";
export const Provder = () => {
  return (
    <div
      className="ManagementSolution  pt-[20px] sm:pt-[40px] md:pt-[60px] lg:pt-[100px]  pb-[60px] md:pb-[60px] sm:pb-[60px] xs:pb-[30px] LandingCompanyOverView PlayerExp PaymentService relative"
      id="vip_service"
      // style={{ height: '800px'}}
    >
      <div className="common-width-landing grid grid-cols-2 items-center gap-[132px]">
        <div className="presentation">
          <Scene triggerHook={0.8} reverse={false} classToggle={"scrollIn"}>
            <span className="flex scrollOut items-center justify-center">
              services
            </span>
          </Scene>
          <Scene triggerHook={0.8} reverse={false} classToggle={"scrollIn"}>
            <h1 className="mt-4 scrollOut mb-6  mx-auto">
              What We <span>Provide</span>
            </h1>
          </Scene>
          <Scene triggerHook={0.8} reverse={false} classToggle={"scrollIn"}>
            <p className="scrollOut mb-20">
              BetWin change your premier platform for seamless cross-border and
              cross-chain transactions tailored to meet the gaming industry's
              demands. Easily convert crypto to cash or gaming chips, catering
              to both online and land-based gaming platforms.
            </p>
          </Scene>
        </div>

        <div className="game-wrapper relative">
          <div className="game-coins-wrapper absolute w-full h-full">
            <img
              src={provider1}
              alt=""
              className="absolute top-[40%] -left-10 -translate-y-[45%]"
            />
            <img src={provider2} alt="" className="absolute top-0 left-24" />
            <img src={provider3} alt="" className="absolute top-0 right-24" />
            <img
              src={provider4}
              alt=""
              className="absolute top-[45%] -right-10 -translate-y-[45%]"
            />
            <img
              src={provider5}
              alt=""
              className="absolute bottom-16 right-6"
            />
            <img
              src={provider6}
              alt=""
              className="absolute -bottom-10  left-1/2 -translate-x-1/2"
            />
            <img src={provider7} alt="" className="absolute bottom-16 left-6" />
          </div>
          <img
            src={betwinProvide}
            alt=""
            className="w-full provide-main-pic"
          />
        </div>
      </div>
    </div>
  );
};
