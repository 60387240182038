import React from "react";

export const CheckboxStyle = ({ type, id }) => {
  return (
    <div className="CheckboxStyle">
      <input type={type} id={id} className="hidden" />
      <label
        htmlFor={id}
        className="cursor-pointer w-5 h-5 rounded-full flex items-center justify-center"
      >
        <span className="w-3 h-3 rounded-full block"></span>
      </label>
    </div>
  );
};
