import React from "react";
import mainpic from "../../assets/img/landing/main.png";
import arrowBtn from "../../assets/img/landing/arrow-btn.svg";
// import coins from "../../assets/img/landing/coins.png";
import coins from "../../assets/img/landing/coins.svg";
import herobg from "../../assets/img/landing/herobg.png";

import { Link } from "react-router-dom";
import Fade from "react-reveal/Fade";
import layerBlur from "../../assets/img/landing/layer-blur-landing-page.png";
export const LandingMain = () => {
  return (
    <Fade>
      <div className="LandingMain  flex items-center" id="home" style={{ backgroundImage: `url(${herobg})`}}>
        <div className="grid grid-cols-2  items-center common-width-landing">
          <img src={layerBlur} alt="" className="absolute right-0 -z-10" />
          <div className="presentation-area">
            <span className="flex items-center justify-center mb-6">
              Welcome to BetWin
            </span>
            <h1 className="relative">
              Getting The Most <br /> Out Of <span>BetWin</span>
            </h1>
            <p className="mb-10 mt-6">
              You will find gaming excellence, innovation, and lasting success
              at BetWin. The gaming industry has boundless potential, and we
              believe we can help make it a reality with our platform.
            </p>

            <Link
              to="/signup"
              className="flex items-center justify-center rounded-lg"
            >
              <img src={arrowBtn} alt="" className="mr-2" />
              Get Started
            </Link>
          </div>

          <div className="img-wrapper relative">
            <div className="main-pic-landing">
              <img src={mainpic} alt="" />
            </div>
            <div className="main-coin">
              <img
                src={coins}
                className="w-full main-coin-landing absolute right-16"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </Fade>
  );
};
