import React from "react";
import casinoPlatform from "../../assets/img/landing/casinoPlatform.svg";
import sportsPlatform from "../../assets/img/landing/sportsPlatform.svg";
import affiliateManagement from "../../assets/img/landing/affiliateManagement.svg";
import jackpotPormotion from "../../assets/img/landing/jackpotPormotion.svg";
import game from "../../assets/img/landing/cards-map.png";

import blurcard from "../../assets/img/landing/blur-cards.png";
import { Scene } from "react-scrollmagic";

export const ForPlayers = () => {
  return (
    <div
      className="LandingCompanyOverView pt-[40px] sm:pt-[60px] md:pt-[80px] lg:pt-[100px]  GamingExcellence relative "
      id="for-players"
    >
      <img
        src={blurcard}
        alt=""
        className="absolute blur-cards -right-8 -top-20 glowing-image-side"
      />
      <div className="grid grid-cols-2 relative z-20  gap-[111px]  items-center common-width-landing">
        <div className="cards-wrapper">
          <h1 className="ForPlayersHeading">For Players</h1>
          <p className="ForPlayersDescription mt-4 mb-8">
            Welcome to our world of excitement and entertainment, where we offer
            an extensive range of top-tier casino products and services designed
            to elevate your gaming experience.
          </p>
          <div className="card !bg-[#252525] !border-[#9435E2] mb-4 rounded-xl flex items-start p-6">
            <Scene triggerHook={0.8} reverse={false} classToggle={"scrollIn"}>
              <img src={casinoPlatform} alt="" className="scrollOut" />
            </Scene>
            <div className="presentation ml-6">
              <h1 className="mb-3 ">Discover Your Gaming Paradise</h1>
              <p>
                Dive into a diverse selection of games across various genres and
                platforms, ensuring excitement for gamers of all kinds. Plus, we
                Return to Player (RTP) games, maximizing your chances of
                substantial wins.
              </p>
            </div>
          </div>

          <div className="card !bg-[#252525] !border-[#9435E2]  mb-4 rounded-xl flex items-start p-6 ">
            <Scene triggerHook={0.8} reverse={false} classToggle={"scrollIn"}>
              <img src={sportsPlatform} alt="" className="scrollOut" />
            </Scene>
            <div className="presentation  ml-6">
              <h1 className="mb-3 ">Competitive Edge</h1>
              <p>
                Gain access to a treasure trove of resources and tutorials to
                hone your gaming skills and rise through the ranks. Connect with
                fellow gamers, form alliances, and master strategies that grant
                you a competitive edge.
              </p>
            </div>
          </div>

          <div className="card !bg-[#252525] !border-[#9435E2]  rounded-xl mb-4 flex items-start p-6 ">
            <Scene triggerHook={0.8} reverse={false} classToggle={"scrollIn"}>
              <img src={affiliateManagement} alt="" className="scrollOut" />
            </Scene>
            <div className="presentation  ml-6">
              <h1 className="mb-3 "> Rewards Galore</h1>
              <p>
                We believe in rewarding dedication and skill. Enjoy a plethora
                of gaming bonuses, competitions, and exclusive in-game rewards,
                from bonus cash to coveted in game items.
              </p>
            </div>
          </div>

          <div className="card !bg-[#252525] !border-[#9435E2]  rounded-xl flex items-start p-6  ">
            <Scene triggerHook={0.8} reverse={false} classToggle={"scrollIn"}>
              <img src={jackpotPormotion} alt="" className="scrollOut" />
            </Scene>
            <div className="presentation  ml-6">
              <h1 className="mb-3 ">Secure Gameplay</h1>
              <p>
                Your security and fair play are paramount to us. Play with
                assurance, knowing that your gaming experience is safeguarded
                against cheating and unfair practices through our stringent
                security measures.
              </p>
            </div>
          </div>
        </div>
        <Scene triggerHook={0.5} reverse={false} classToggle={"scrollIn"}>
          <div className="scrollOut relative ">
            <img src={game} className="w-full" alt="" />
          </div>
        </Scene>
      </div>
    </div>
  );
};
