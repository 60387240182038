import React from "react";
import { Link } from "react-router-dom";
import { Scene } from "react-scrollmagic";
import { GameOperatorsList } from "../../utils/GameOperatorsList";
import blurgo from "../../assets/img/landing/blur-go.png";

export const GameOperator = () => {
  return (
    <div
      className="CasinoPlatform Services  pt-[30px] sm:pt-[40px] md:pt-[60px] lg:pt-[100px] relative z-10"
      id="game-op"
      style={{ backgroundColor: "#1A1A1B" }}
    >
      <img
        src={blurgo}
        alt=""
        className="absolute blur-cards -right-0 -top-2 -z-50 glowing-image-side"
      />
        <div className="common-width-landing text-left">
      <Scene triggerHook={0.8} reverse={false} classToggle={"scrollIn"}>
      

        <p className="flex text-[#9df74a] items-center scrollOut ">
          Gaming Operators
        </p>
      
      </Scene>
        </div>
      <Scene triggerHook={0.8} reverse={false} classToggle={"scrollIn"}>
        <h1 className="mt-4 mb-6 scrollOut common-width-landing">
          For <span>Gaming</span> Operators
        </h1>
      </Scene>

      <div className="CasinoPlatformFirstWrapper flex items-center justify-between common-width-landing">
        <p className="w-[891px]">
          Are you ready to propel your gaming operations to new heights? Partner
          with BetWin and unlock a world of opportunities to elevate your game,
          boost your revenue, and deliver unparalleled user experiences. Here's
          how we can help you achieve extraordinary success.
        </p>
        <Link to="/" className="flex items-center justify-center rounded-lg">
          Get Started
        </Link>
      </div>

      <div className="cards-wrapper grid grid-cols-4 gap-6 gap-y-10 common-width-landing">
        {GameOperatorsList.map((EachCard, key) => (
          <div
            className="card rounded-lg !bg-[transparent] !border-0  flex flex-col items-start justify-start"
            key={`b_${key}`}
          >
            <Scene triggerHook={0.8} reverse={false} classToggle={"scrollIn"}>
              <img src={EachCard.img} alt="" className="scrollOut" />
            </Scene>
            <h1 className="mt-6 mb-3 text-left">{EachCard.heading}</h1>
            <p className="text-left">{EachCard.desc}</p>
          </div>
        ))}
      </div>
    </div>
  );
};
