import React from "react";
import { Controller } from "react-scrollmagic";
import "../assets/css/landing/terms.css";
import { LandingFooter } from "../components/landing/LandingFooter";
import { LandingHeader } from "../components/landing/LandingHeader";
import { Provder } from "../components/landing/Provder";

export const  GameProviders = () => {
  return (
    <Controller>
      <div className="Landing TermsConditions">
        <LandingHeader />
        <Provder />
        <LandingFooter />
      </div>
    </Controller>
  );
};
