import img1 from "../assets/img/landing/bet1.svg";
import img2 from "../assets/img/landing/bet2.svg";
import img3 from "../assets/img/landing/bet3.svg";
import img4 from "../assets/img/landing/bet4.svg";

export const GameOperatorsList = [
  {
    img: img1,
    heading: "Elevate Your Game",
    desc: "Stay ahead of the ever-evolving gaming landscape by harnessing our cutting-edge technology and industry expertise to enhance your gaming offerings.",
  },

  {
    img: img2,
    heading: "Marketing Solutions",
    desc: "Stand out in the fiercely competitive gaming industry with customized marketing strategies tailored to your unique objectives.",
  },

  {
    img: img3,
    heading: "Data-Driven Insights",
    desc: "Leverage the power of data analytics to gain invaluable insights into player behavior, preferences, and trends. This data-driven approach will guide.",
  },

  {
    img: img4,
    heading: "Monetization Strategies",
    desc: "Unlock new revenue streams while ensuring player satisfaction. Achieve the perfect balance between profitability and player engagement.",
  },
];
